.ripple {
  position: absolute;
  pointer-events: none;
  transform: scale(0);

  div {
    width: 100%;
    height: 100%;
    background-color: currentColor;

    &.circle {
      border-radius: 50%;
    }

    &.square {
      border-radius: 4px;
    }
  }

  &[data-visible] {
    opacity: 1;
    transform: scale(1);

    div {
      opacity: 0.4;
      transform: scale(1);
      animation: grow 0.55s ease-in-out;
    }
  }

  &[data-exiting] {
    opacity: 0;
    animation: fadeout 0.55s ease-in-out, hide 0s linear 0.55s forwards;
  }

  @keyframes grow {
    0% {
      opacity: 0.1;
      transform: scale(0);
    }
    100% {
      opacity: 0.4;
      transform: scale(1);
    }
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes hide {
    100% {
      transform: scale(0);
    }
  }
}
