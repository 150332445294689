@import 'helpers';

.card {
  margin-top: 1rem;

  h2 {
    @include default-header;
  }
}

.container {
  width: 100%;
  height: 100%;

  section {
    font-family: 'Open Sans';
    font-style: normal;
    h3 {
      color: #5d5a71;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }
  }

  section + section {
    margin-top: 20px;
  }
}
