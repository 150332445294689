@import './theme';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin hover-growing-shadow(
  $size: 100%,
  $color: rgb(32, 33, 36),
  $shape: circle,
  $duration: 0.2s
) {
  outline: none;

  & > div {
    outline: none;
  }

  & > div::before {
    content: '';
    display: block;
    position: absolute;
    top: (100% - $size) / 2;
    left: (100% - $size) / 2;
    width: $size;
    height: $size;
    z-index: 1;

    background-color: rgba($color, lightness($color) / 1000 + 0.09);

    @if $shape == circle {
      border-radius: 100%;
    } @else {
      border-radius: 4px;
    }

    opacity: 0;
    transform: scale(0);

    transition-duration: $duration;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: transform, opacity;
  }

  &:focus > div::before,
  & > div:hover::before {
    opacity: 1;
    transform: scale(1);
  }
}

@mixin input-box {
  display: flex;
  align-items: center;
  flex: 1 1 auto;

  color: darken($regular-color, 13.73);
  background-color: transparent;

  font-family: $font-family;
  font-size: $font-size;
  line-height: $font-size * 1.14;
  font-weight: 500;

  min-width: 0;
  padding: 8px;
  margin: 0;
  border: none;
  outline: none;
}

@mixin input-border($color, $width: 1px) {
  box-shadow: 0px 0px 0px $width $color;
}

@mixin hover-border-underline-center(
  $color: #fff,
  $height: 2px,
  $bottom: 0,
  $duration: 0.2s
) {
  position: relative;
  outline: none;

  & > div::after {
    content: '';
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: $bottom;
    background: $color;
    height: $height;

    transition-property: left, right;
    transition-duration: $duration;
    transition-timing-function: ease-out;
  }
}

@mixin pretty-scroll-bar($width: 14px) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 3px solid transparent;
    border-radius: 7px;
    box-shadow: inset 0 0 0 10px;
    color: $primary-color;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin round-input-content {
  &:first-child {
    .input:first-child {
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
    }
  }

  &:last-child {
    .input:last-child {
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
  }
}

@mixin ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin card {
  background-color: #fff;
  filter: drop-shadow(0px 20px 27px rgba(0, 0, 0, 0.05));
  border-radius: 12px;
}

@mixin default-card {
  @include card;
  padding: 20px 15px 15px;
}

@mixin default-header {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #252f40;
  margin-bottom: 20px;
}

@mixin fill-parent($width: 0, $height: 0) {
  min-width: 0;
  min-height: 0;

  @if $width != 0 {
    width: $width;
  }

  @if $height != 0 {
    height: $height;
  }
}

@mixin fill-parent-all {
  @include fill-parent(100%, 100%);
}

@mixin fill-parent-width {
  @include fill-parent($width: 100%);
}

@mixin fill-parent-height {
  @include fill-parent($height: 100%);
}

@mixin break-word {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@mixin tippy-popup($color: $system-white, $background: $system-black) {
  .popup {
    font-family: $font-family !important;
    font-size: $font-size !important;
    font-weight: 500 !important;
    line-height: $font-size/10px !important;
    color: $color !important;
    background-color: $background !important;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);

    :global(.tippy-arrow) {
      color: $background !important;
    }
  }
}

@mixin modal-popup {
  .popup {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 30px;

      span {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        font-size: 18px;
        color: #252f40;
      }

      span:last-child {
        margin-left: 5px;
        color: lighten(saturate(adjust-hue($primary-color, 1), 5), 13.73);
      }
    }

    .container {
      display: flex;
      padding: 30px;
      justify-content: center;
      flex-direction: column;
      height: 360px;

      .content {
        margin: 8px 0;
      }

      .footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
  }
}
