@import 'helpers';
@import 'theme';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.grid {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.modalContent {
  width: calc(100% - 30px);
  max-height: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 20px;

  .modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      font-family: $font-family;
      font-size: 18px;
    }

    span {
      cursor: pointer;
    }
  }
}

.expensePart {
  cursor: pointer;
  width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 18px 16px;
  font-family: $font-family;
  border: 1px solid #eeeeee;
  row-gap: 10px;

  .title {
    font-size: 18px;
    color: #333333;
    font-weight: 600;
    margin: 0;
  }

  .subtitle {
    color: #5d5a71;
    font-weight: 400;
    font-size: 14px;
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 8px;
  }

  .footer {
    @include flex-center;
    justify-content: space-between;
    width: 100%;
  }
}
