@import 'helpers';
@import 'theme';

:export {
  inputHeight: $input-height;
}

.passwordInput {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  height: $input-height;
  outline: none;

  .icon {
    @include flex-center;
    flex-shrink: 0;
    width: $input-height;
    font-size: 14px;
    color: lighten(desaturate(adjust-hue($primary-color, 3), 4.54), 34.9);
  }

  &.active {
    .icon {
      color: $primary-color;
    }
  }

  &.disabled,
  &.readonly {
    .icon {
      color: $disabled-color;
    }
  }

  .input {
    @include input-box;

    @at-root .disabled#{&} {
      color: $disabled-color;
      font-weight: 300;
    }
  }

  @include round-input-content;
}
