@import 'helpers.scss';

$box-size: 25px;
$box-margin: 3px;

:export {
  boxSize: $box-size;
  boxMargin: $box-margin;
}

.colorPicker {
  display: flex;
  flex-direction: column;

  .box {
    flex-shrink: 0;
    width: $box-size;
    height: $box-size;
    border-radius: 5px;
  }

  .anchor {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    outline: none;

    .label {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 500;
      color: $system-dark-gray;
      margin: 0 5px;
    }
  }
}

.palette {
  display: flex;
  flex-wrap: wrap;
  padding: $box-size / 2;
  border-radius: $box-size / 2;
  box-shadow: 0 0.2rem 0.5rem rgba(69, 77, 93, 0.3);
  background-color: #fff;

  .box {
    @include flex-center;

    flex-shrink: 0;
    width: $box-size;
    height: $box-size;
    border-radius: 5px;
    margin: $box-margin;
    color: #fff;
    cursor: pointer;
  }
}
