@import 'helpers';

.pizzaProgress {
  @include flex-center;
  position: relative;

  svg {
    circle {
      stroke-dashoffset: 0;
      opacity: 0.2;
      transition: stroke-dashoffset 1s linear;

      &.bar {
        opacity: 1;
      }
    }
  }
}
