@import 'helpers';

.scrollPanel {
  display: flex;
  position: relative;
  flex-direction: column;
  @include fill-parent-all;
  box-sizing: border-box;
  overflow: hidden;
  transition: padding 0.2s;

  .content {
    display: flex;
    position: relative;
    flex-direction: column;
    @include fill-parent-all;
    overflow: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

:export {
  barWidth: $active-scroll-bar-width;
}
