@import 'theme';

.modal {
  $animation-duration: 0.2s;
  $bg-color-1: rgba($primary-color, 0);
  $bg-color-2: rgba(104, 104, 104, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  z-index: 9990;

  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  animation-duration: $animation-duration;
  animation-fill-mode: forwards;

  .frame {
    overflow: hidden;

    min-width: 300px;
    max-width: calc(100vw - 40px);

    background-color: $section-bg-color;
    border-radius: 8px;
    box-shadow: 0 0.2rem 0.5rem rgba(69, 77, 93, 0.3);

    animation-duration: $animation-duration;
    animation-fill-mode: forwards;
  }

  &.visible {
    animation-name: fadeIn;
    animation-timing-function: ease-out;

    .frame {
      animation-name: grow;
      animation-timing-function: ease-out;
    }
  }

  &.leaving {
    animation-name: fadeOut;
    animation-timing-function: ease-in;

    .frame {
      animation-name: shrink;
      animation-timing-function: ease-in;

      & > * {
        opacity: 0;
      }
    }
  }

  @keyframes grow {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes shrink {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      background-color: $bg-color-1;
    }
    100% {
      background-color: $bg-color-2;
      backdrop-filter: blur(8px);
    }
  }

  @keyframes fadeOut {
    0% {
      background-color: $bg-color-2;
      backdrop-filter: blur(8px);
    }
    100% {
      background-color: $bg-color-1;
    }
  }
}
