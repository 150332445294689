@import 'helpers';

.alertMessage {
  @include flex-center;
  flex-direction: column;
  font-family: $font-family;
  flex: 1;
  padding: 50px;
  text-align: center;

  h4 {
    font-size: $font-size * 1.71;
    margin-bottom: 10px;
  }

  i {
    font-size: $font-size * 5;
    color: rgb(255, 199, 0);
  }

  span {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 500;
    color: $regular-color;
    padding: 3px;
    margin-bottom: 10px;
  }
}
