@import 'helpers';

.back {
  @include flex-center;
  margin-right: 20px;

  animation: move 0.6s ease;
  @keyframes move {
    0% {
      transform: translateX(80%);
    }
  }
}
