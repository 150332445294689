@import 'theme';
@import 'helpers';

.milestoneEditor {
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  color: $text-color-light-gray;

  font-size: 14px;
  font-weight: 600;

  .header {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .backButton {
      display: flex;
    }

    .title {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      font-size: 20px;
      font-weight: 600;
      color: $text-color-primary-variant-dark;
    }
  }

  .buttonHeader {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    margin-bottom: 30px;

    .uploadButton {
      .underButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .iconAlign {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }

  .conclusionGraph {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-family: 'Roboto';

    .legend {
      margin-right: 20px;
      h3 {
        font-size: 16px;
        font-weight: 400;
        font-size: 14px;
        color: #9c9c9c;

        text-wrap: nowrap;
      }
      strong {
        font-family: $font-family;
        font-weight: 600;
        font-size: 30px;
        color: #000000;
      }
    }
  }

  .sectionTitle {
    padding-top: 20px;
    font-size: 16px;
    font-weight: 600;
    color: $text-color-primary-variant-dark;
  }
}
