@import 'helpers';
@import 'theme';

.title {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.553191px;
  color: $text-color-primary-variant-dark;
  padding: 15px 0;
}

.dashedContainer {
  border: 1px dashed #868686;
  border-radius: 9px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
}

.container {
  font-family: $font-family;
  font-weight: 600;

  h1 {
    padding: 8px 0;
    font-size: 16px;
    color: $text-color-primary-variant-dark;
  }
  h2 {
    font-size: 12px;
    color: $system-text-gray;
  }
  .helper {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    width: 100%;
    white-space: nowrap;
    > span {
      margin-top: 10px;
      font-weight: normal;
    }
  }
}

.buttonHeader {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;

  .uploadButton {
    .underButtons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .iconAlign {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.updateWpErrorContainer {
  display: flex;

  .updateWpErrorSubtitle {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .updateWpErrorFinish {
    padding: 3px 0;
  }
}
