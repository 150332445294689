@import 'helpers.scss';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

tr {
  .center {
    @include flex-center;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  z-index: 3;
  padding: 5px 0;
  transform: translateY(6px);
  border-radius: $input-border-radius * 2;

  &.added {
    background: rgba(187, 235, 196, 0.19);
    border: 1px solid #2f6f3a;
  }

  &.modified {
    background: rgba(255, 245, 0, 0.19);
    border: 1px solid #fff500;
  }

  &.removed {
    border: 1px solid #f00;
    background: rgba(255, 0, 0, 0.19);
  }
}

tbody {
  .removeBg {
    background: #fdb8c0;
  }

  .addBg {
    background: #bbebc4;
  }

  tr {
    .button {
      @include flex-center;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;
    }

    &:hover {
      .button {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

:export {
  inputHeight: $input-height;
}
