@import 'helpers.scss';

.grid {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  height: 0;

  user-select: none;

  svg {
    position: relative;
    stroke: #e5e6e7;
    stroke-width: 1px;
    stroke-linecap: square;
    stroke-linejoin: round;

    .deadline {
      line {
        position: absolute;
        z-index: 9999;
      }
    }

    .anchor {
      line {
        stroke: #607d8b;
        stroke-width: 3px;
        stroke-dashoffset: 4;
        stroke-dasharray: 1, 5;
      }

      &:last-of-type {
        pointer-events: none;

        line {
          stroke-dasharray: none;
        }
      }

      rect {
        stroke: none;
        fill: transparent;
      }

      &:active {
        line {
          stroke: #888;
        }
      }
    }

    rect.shadow {
      stroke: none;
      fill: transparentize($color: #000000, $amount: 0.96);
    }

    line.shadow {
      stroke: #000;
      stroke-width: 3px;
      stroke-dashoffset: 0;
      stroke-dasharray: 1, 6;
      stroke-linecap: round;
      transition: stroke-width 0.3s linear, stroke 0.3s linear;
    }

    line {
      transition: stroke 0.3s linear;
    }
  }

  &:not(.active) {
    svg {
      .anchor:hover {
        cursor: col-resize;

        line {
          stroke: #000;
        }
      }
    }
  }

  &.readonly {
    pointer-events: none;
  }

  .months {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;

    > div {
      display: flex;

      position: absolute;
      bottom: 0;

      span {
        @include flex-center;
        font-family: $font-family;
        font-size: $font-size * 0.72;
        font-weight: 400;
        color: #607d8b;
      }
    }
  }
}
