@import 'helpers';
@import 'theme';

@include tippy-popup;
.container {
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;

  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  height: fit-content !important;
  max-height: fit-content !important;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #78909c;

    .title {
      display: flex;
      flex-direction: row;

      h3 {
        font-family: 'Open Sans', serif;
        font-style: normal;

        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        display: flex;
        align-items: center;
        letter-spacing: 0.44px;

        color: rgba(0, 0, 0, 0.87);
        margin-left: 10px;
      }
    }

    .filter {
      align-items: center;
    }
  }

  .graph {
    .barGraph {
      margin-top: 5px;

      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $system-medium-gray;
      }
      .bar {
        height: 31px;
        width: auto;
        left: 0;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        animation: right-animate 1.2s ease-in-out;
      }

      hr {
        border: none;
        border-top: 1px dotted #ece9f1;
      }

      @keyframes right-animate {
        100% {
          width: var(width);
        }
        0% {
          width: 0;
        }
      }
    }
  }

  .footer {
    display: flex;
    padding: 5px;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    font-size: 14px;
    line-height: 22px;
    color: $system-medium-gray;
  }
}
