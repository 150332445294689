@import 'helpers';

.pdfViewer {
  display: flex;
  flex: 1;
  border-radius: 3px;
  min-height: 0;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;

  .document {
    margin: auto;
    max-width: 100%;
  }

  &.fade {
    > * {
      animation: fadeIn 0.2s linear forwards;
    }
  }
}
