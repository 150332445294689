@import 'theme';
@import 'helpers';

.container {
  padding: 8px 12px;
  width: 70%;
  background-color: #ffeeda;
  margin-bottom: 20px;
  border-radius: 5px;

  font-family: $font-family;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $system-dark-gray;
  display: flex;
  align-items: center;

  svg {
    margin-right: 40px;
  }
}
