@import 'helpers';

.selector {
  @include flex-center;
  flex-direction: column;
  margin: auto;

  .header {
    font-family: $font-family;

    text-transform: uppercase;
    text-align: center;

    div {
      &:first-child {
        font-size: 27px;
        font-weight: 600;
        color: $primary-color;
        letter-spacing: 0.8px;
      }

      &:last-child {
        font-size: 12px;
        font-weight: 500;
        color: $regular-color;
      }
    }
  }

  .content {
    @include flex-center;
    padding: 30px 0;
  }

  .card {
    @include flex-center;
    flex-direction: column;
    position: relative;
    border-radius: $input-border-radius;
    background: #ffffff;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    width: 240px;
    height: 144px;
    margin: 14px;
    cursor: pointer;

    transition: all 0.2s ease-in;

    &:hover {
      box-shadow: 0 3px 5px 0 rgba(#c1c1c1, 0.5);
    }

    &.selected {
      background-color: $primary-color;

      .badge,
      .label {
        color: #fff;
      }

      .check {
        @include flex-center;
      }
    }

    .badge {
      font-size: 41px;
      color: $icon-color;
    }

    .label {
      font-family: $font-family;
      font-size: 18px;
      font-weight: 600;
      color: $system-black;
      text-transform: uppercase;
    }

    .check {
      display: none;
      position: absolute;
      top: -12px;
      right: -12px;

      width: 31px;
      height: 31px;
      border-radius: 50%;
      color: $primary-color;
      background-color: #fff;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    span {
      font-size: 0.875rem;
      font-weight: 400;
      font-family: $font-family;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
