@import 'helpers';

.vstack {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.center {
    align-items: center;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.stretch {
    align-items: stretch;
  }

  &.width {
    @include fill-parent-width;
  }

  &.height {
    @include fill-parent-height;
  }

  &.all {
    @include fill-parent-all;
  }
}
