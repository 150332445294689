@import 'theme';

.container {
  font-family: $font-family;
  font-weight: 600;
  h1 {
    padding: 25px 0;
    padding-bottom: 0;
    font-size: 16px;
    color: $text-color-primary-variant-dark;
  }
}
