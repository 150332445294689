@import 'helpers';

.wrapper {
  overflow: hidden;
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 18px;
  background-color: #fff;
  padding: 0.8rem 3rem;
  border-radius: 5px;
  box-shadow: 0 0.2rem 0.5rem rgba(69, 77, 93, 0.3);

  .title {
    color: #2f6f3a;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
  }

  .calendars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .container {
      $cell-column: var(--cell-column);

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1rem;

        .dateHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          padding: 0.5em 0.25em;

          .month {
            font-weight: 700;
            font-size: 16px;
            color: $system-black;
            text-align: center;
            line-height: 16px;
            user-select: none;
          }

          .year {
            @include flex-center;

            font-family: 'Roboto';
            margin-left: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: $system-black;

            background-color: transparent;
            border: none;
            cursor: pointer;

            svg {
              transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }

            &.open {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
        .arrow {
          font-size: 1.5em;
          cursor: pointer;
          color: $system-black;
          transition: opacity 0.5s ease-in-out;

          &:hover {
            opacity: 0.5;
          }
          &.left {
            left: 1.5rem;
            transform: rotate(90deg);
          }
          &.right {
            right: 1.5rem;
            transform: rotate(-90deg);
          }
        }
      }

      .grid {
        display: grid;
        grid-template: repeat(7, auto) / repeat(7, auto);
      }

      .cell {
        text-align: center;
        align-self: center;
        user-select: none;
        grid-column: var(--cell-column) / span 1;

        font-weight: 500;
        font-size: 13px;
        line-height: 18px;

        &.day {
          padding: 0 0.5rem;
          color: $system-black;
          padding-bottom: 0.7rem;
        }

        &.date {
          padding: 0.2rem 0;
          cursor: pointer;
          color: #e0e0e0;
          transition: all 0.4s ease-out;
          border-radius: 37px !important;

          grid-row: var(--cell-row) / span 1;

          &.inMonth {
            font-weight: 500;
            color: $system-black;
          }
          &.highlighted {
            color: $text-color-primary !important;
            background: #2f6f3a !important;
            font-weight: 400;
          }

          &.today {
            color: #da5353 !important;

            &:not(.highlighted):hover {
              color: #2f6f3a !important;
              background: rgba(68, 207, 92, 0.075) !important;
            }
          }

          &:not(.highlighted):hover {
            color: #2f6f3a !important;
            background: rgba(68, 207, 92, 0.075);
          }
        }
      }
    }
  }
  hr {
    margin-top: 10px;
    height: 1px;
    background-color: #ccc;
    border: none;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 0.6rem;

    .button {
      border: none;
      margin-left: 1rem;
      padding: 0.5rem 0.8rem;
      border-radius: 8px;
      font-weight: 700;
      font-size: 10px;
      text-transform: uppercase;
      color: rgba(246, 249, 250, 1);
      background-color: rgba(68, 207, 92, 1);
      transition: all 0.4s ease-out;
      cursor: pointer;

      &.disabled {
        color: rgba(246, 249, 250, 1);
        background-color: rgba(68, 207, 92, 0.4);
        cursor: not-allowed;
      }
      &:not(.disabled):hover {
        background-color: #2f6f3a;
      }
    }
  }
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: $input-height;

  font-family: $font-family;
  font-size: $font-size;
  font-weight: 400;
  line-height: $input-height * 0.48;
  color: $regular-color;

  padding: 4px 4px 4px 8px;
  box-sizing: border-box;
  margin-right: 15px;

  &.selected {
    color: $select-text-hover-color;
    background-color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);
  }

  &:not(.selected) {
    color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);
  }
}
