@import 'helpers';
@import 'theme';

.selectedProjectAccount {
  font-family: $font-family;
  display: flex;
  align-items: center;
  gap: 38px;

  .detailItem {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    letter-spacing: 0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #5d5a71;
    text-transform: uppercase;
    span {
      color: #333333;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.07px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      &:first-of-type {
        color: #5d5a71;
        font-weight: 600;
      }
    }
  }
}

.content {
  font-size: $font-size;
  font-family: $font-family;
  text-align: center;
  color: $system-black;
  background-color: $table-bg-color;
  border-collapse: collapse;
  border-spacing: 0;
  width: 90%;

  td:nth-child(1) {
    text-align: left;
  }

  th:nth-child(1) {
    text-align: left;
    padding-left: 10px;
  }

  tbody {
    border-top: 1px solid $system-light-gray;
    border-bottom: 1px solid $system-light-gray;

    td {
      border-bottom: 1px solid $system-light-gray;
      color: $system-dark-gray;
      font-weight: 400;
    }
  }

  tr {
    height: 40px;
  }
}
