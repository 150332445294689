@import 'TableList.module.scss';

.tableData {
  @extend .tableList;
  height: 100%;

  table {
    table-layout: fixed;
    width: 100%;

    thead {
      th {
        padding: 10px !important;
        padding-bottom: 0px !important;
        &:first-child {
          padding-left: 0 !important;
        }
        span {
          color: $system-text-gray;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }

    tbody {
      tr {
        &:not(.overlay) {
          height: $input-height !important;
        }

        &.empty {
          height: $input-height * 2 !important;

          &:hover {
            background: none;
          }

          td {
            text-align: center;
          }
        }
      }

      td {
        padding: 10px 7px !important;
        &:first-child {
          padding-left: 0 !important;
        }
      }
    }
  }

  .addData {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    max-width: 200px;
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 7px 3px;
    color: $system-text-gray;
    border: 1px dashed $system-text-gray;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $background-color;
    }
  }
}

:export {
  inputHeight: $input-height + 6;
}
