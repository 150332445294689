@import 'helpers';

.button {
  @include flex-center;
  position: relative;
  margin: 7px 9px;
  width: 120px;
  height: 35px;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;

  span {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    color: $system-medium-gray;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    stroke: $system-medium-gray;
    stroke-width: 1px;
    stroke-dashoffset: 0;
    stroke-dasharray: 2;
    stroke-linecap: square;
    stroke-linejoin: round;
    fill: none;
  }

  &:hover {
    background-color: #f6f9fa;

    span {
      color: darken(desaturate(adjust-hue($primary-color, 1), 13.64), 9.22);
    }

    svg {
      stroke: darken(desaturate(adjust-hue($primary-color, 1), 13.64), 9.22);
    }
  }
}
