@import 'theme';
@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.field {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 2px;

  &.classic {
    margin: 10px 0;
  }

  $fg-color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);

  .header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    user-select: none;

    label.label {
      font-family: $font-family;
      font-style: normal;
      font-size: $font-size;
      font-weight: 600;
      line-height: $font-size * 1.14;
      text-transform: uppercase;

      color: $system-text-gray;

      @at-root .disabled#{&} {
        color: $disabled-color;
      }
    }
    span {
      font-family: $font-family;
      font-size: 12px;
      font-weight: 300;
      margin: 1.5px;
      line-height: 1;
      text-transform: uppercase;

      &.required {
        color: $obligated-red;
      }

      &.optional {
        color: $system-dark-gray;
      }

      @at-root &.disabled#{&} {
        color: $disabled-color;
      }
    }
  }

  .body {
    position: relative;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $input-border-radius * 2;

    transform: scaleX(1.05) scaleY(1.2);
    transform-origin: center center;

    &.modified {
      background: rgba(255, 245, 0, 0.19);
      border: 1px solid #fff500;
      z-index: 5;
    }
  }

  &.readonly {
    .header span.optional {
      visibility: hidden;
    }
  }
}
