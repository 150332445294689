@import 'helpers.scss';

.header {
  display: flex;
  width: 100%;
  margin: 0.2rem;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: $active-scroll-bar-width;

  .info {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;

    > div {
      @include flex-center;
      cursor: pointer;
      user-select: none;

      > .icon {
        @include flex-center;
        color: $primary-color;
        font-size: 20px;
        margin: 0 8px;
      }

      > span {
        font-family: $font-family;
        font-size: 16px;
        font-weight: 400;
        color: $primary-color;
      }

      &:hover {
        > span {
          text-decoration: underline;
        }
      }
    }
  }
}

.infoText {
  padding: 30px 32px;
  text-align: justify;
  height: auto;
  font-family: $font-family;
}
