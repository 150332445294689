@import 'helpers';

.errorMessage {
  @include flex-center;
  flex-direction: column;
  flex: 1;
  padding: 50px;

  svg {
    margin-bottom: 1rem;
  }

  color: $error-color;
  font-size: 50px;

  span {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 500;
    color: $regular-color;
    padding: 3px;
  }
}
