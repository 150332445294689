@import 'helpers';
@import 'theme';

.insideHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: $system-black;
    line-height: 24px;
  }
}
