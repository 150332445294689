@import 'helpers';
@import 'theme';

.cell {
  @include flex-center;
  border-radius: 4px;
  background-color: $general-light-green;
  margin: 7px 9px;
  box-sizing: border-box;
  padding: 5.5px 20px;
  min-width: 120px;
  min-height: 35px;
  user-select: none;
  cursor: pointer;

  > div {
    display: flex;
    font-family: $font-family;
    font-size: 12px;
    font-weight: 400;
    color: darken(desaturate(adjust-hue($primary-color, 1), 13.64), 9.22);
    box-sizing: border-box;

    &.label {
      @include flex-center;
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin: 0 12px 0 0;
      background-color: transparentize($color: #fff, $amount: 0.69);
    }

    &.name {
      flex: 1;
      font-size: 14px;
      @include break-word;
    }
  }

  &.root {
    padding: 12px;
  }
}
