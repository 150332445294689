@import 'helpers';

.editor {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;

  .closeButton {
    display: flex;
    justify-content: space-between;

    margin-bottom: 20px;

    span {
      font-family: $font-family;
      font-weight: 600;
    }
  }

  .title {
    background-color: #efefef;
    border-radius: 5px;
    padding: 10px 13px;
    margin: 24px 0;

    font-family: $font-family;
    font-size: 11px;
    font-weight: 400;
    color: #000;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .delete {
      @include flex-center;
      cursor: pointer;
      user-select: none;

      .icon {
        @include flex-center;
        color: $danger-color;
        font-size: 20px;
        margin: 0 9px;
      }

      span {
        font-family: $font-family;
        font-size: 12px;
        font-weight: 400;
        color: $danger-color;
      }
    }
  }
}
