.hstack {
  display: flex;
  flex-wrap: nowrap;

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
}
