@import 'helpers';

.editor {
  display: flex;
  flex-direction: column;
  width: 900px;
  height: 800px;
  padding: 24px;
  box-sizing: border-box;
  background-color: #fff;

  .closeButton {
    display: flex;
    justify-content: space-between;
    span {
      font-family: $font-family;
      font-weight: 600;
    }
  }

  .box {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .delete {
      @include flex-center;
      cursor: pointer;
      user-select: none;

      .icon {
        @include flex-center;
        color: $danger-color;
        font-size: 20px;
        margin: 0 9px;
      }

      span {
        font-family: $font-family;
        font-size: 12px;
        font-weight: 400;
        color: $danger-color;
      }
    }
  }
}

@media (min-width: 1000px) and (max-width: 1500px) {
  .editor {
    zoom: 0.8;
  }
}

@media (min-width: 1501px) and (max-width: 1920px) {
  .editor {
    zoom: 0.9;
  }
}
