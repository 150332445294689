@import 'helpers';

.tab {
  display: flex;
  flex-direction: column;

  min-width: 0;
  min-height: 0;
  height: fit-content;
  overflow-x: hidden;

  &.default {
    width: 100%;
    height: 100%;
  }

  .header {
    $height: 45px;
    $offset: 20px;

    display: flex;
    min-width: 0;
    width: fit-content;

    font-family: $font-family;
    font-size: $font-size;
    font-weight: 700;

    &.styledHeader {
      background: #c4c4c47d;
    }

    &.default {
      width: 100%;
      border-bottom: 1px solid #c6c2de;
    }

    &.spaced {
      width: 100%;
      border-bottom: 1px solid #c6c2de;

      padding: 6px 35px;
      border-radius: 12px;
      background: #fff;

      box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
      width: 80%;
    }

    > div.system {
      display: flex;
      border-bottom: 4px solid $primary-color;

      .titleContainer {
        display: inline-flex;
        justify-content: center;

        .adornment {
          position: absolute;
          right: 0;
        }
      }

      > div {
        display: flex;
        align-items: flex-end;
        outline: none;

        > div {
          height: $height;
          padding: 15px 10px 12px 10px;
          box-sizing: border-box;
          outline: none;
          min-width: 96px;
          text-align: center;

          color: lighten($regular-color, 32.94);
          background-color: transparent;
          background-clip: padding-box;

          white-space: nowrap;
          user-select: none;
          cursor: pointer;

          transition: all 0.3s;

          border-top-left-radius: $offset;
          border-top-right-radius: $offset;

          &[data-active] {
            color: $text-color-primary;
            background-color: $primary-color;
          }
        }
      }

      @include hover-border-underline-center($bottom: -3px, $color: #fff);
    }

    > div.default {
      display: flex;

      .titleContainer {
        display: inline-flex;
        justify-content: center;

        .adornment {
          position: absolute;
          right: 0;
        }
      }

      > div {
        display: flex;
        align-items: flex-end;
        outline: none;

        > div {
          height: $height;
          padding: 15px 10px 12px 10px;
          box-sizing: border-box;
          outline: none;
          min-width: 96px;
          text-align: center;
          color: $text-color-primary-variant-light;
          background-color: transparent;
          background-clip: padding-box;

          white-space: nowrap;
          user-select: none;
          cursor: pointer;

          transition: all 0.3s;

          &[data-active] {
            color: $text-color-primary-variant-dark;
            font-weight: 700;

            &:before {
              content: '';
              position: absolute;
              right: 0;
              width: 100%;
              bottom: -1px;
              background: #25213b;
              height: 2px;
            }
          }

          &[data-changes='true'] {
            color: $text-color-primary-variant-dark;
            font-weight: 700;
            background: rgba(255, 245, 0, 0.19);
            border: 1px solid #fff500;
            border-radius: $input-border-radius * 2;

            &:before {
              content: '';
              position: absolute;
              right: 0;
              width: 100%;
              bottom: -1px;
              background: #25213b;
              height: 2;
            }
          }
        }
      }

      &:first-child {
        padding-left: 4px;
      }

      @include hover-border-underline-center($bottom: -1px, $color: #fff);
    }

    > div.spaced {
      .titleContainer {
        display: inline-flex;
        justify-content: center;

        .adornment {
          position: absolute;
          right: 0;
        }
      }

      > div {
        display: flex;
        align-items: flex-end;
        outline: none;

        > div {
          height: $height;
          padding: 15px 10px 12px 10px;
          box-sizing: border-box;
          outline: none;
          min-width: 96px;
          text-align: center;
          color: $text-color-primary-variant-light;
          background-color: transparent;
          background-clip: padding-box;

          white-space: nowrap;
          user-select: none;
          cursor: pointer;

          transition: all 0.3s;

          &[data-active] {
            color: $text-color-primary-variant-dark;
            font-weight: bolder;

            &:before {
              content: '';
              position: absolute;
              right: 0;
              width: 100%;
              bottom: -1px;
              background: #25213b;
              height: 2px;
            }
          }
        }
      }

      @include hover-border-underline-center($bottom: -1px, $color: #fff);
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    min-height: 0;
  }

  .cascade {
    padding-left: 10px;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    min-height: 0;
  }
}

.spaced {
  gap: 25px;
}
