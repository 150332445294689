@import 'helpers';
@import 'theme';

@include tippy-popup;

.valueRange {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  height: $input-height;
  outline: none;

  .icon {
    @include flex-center;
    flex-shrink: 0;

    font-size: 14px;
    color: #686868;
    margin-left: 1em;
    align-items: center;
  }

  &.active {
    .icon {
      color: #000000;
    }
  }

  .input {
    @include input-box;
    @at-root .disabled#{&} {
      color: $disabled-color;
      font-weight: 300;
    }

    @at-root .readonly#{&} {
      color: darken($text-color-primary, 33.33);
    }

    .content {
      cursor: pointer;
      display: flex;
      position: relative;
      align-items: center;
      flex: 1 1 0%;
      flex-wrap: wrap;
      overflow: hidden;
      box-sizing: border-box;
      transition: all 0.2s ease-in-out;
      text-transform: none;

      &.placeholder {
        font-size: 12px;
        color: darken($text-color-primary, 33.33);
      }

      &.range {
        display: flex;
        font-size: 12px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }
  }

  .adornment {
    @include flex-center;
    flex: 0 0 auto;
    color: black;
    background-color: transparent;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 400;
    line-height: $font-size * 1.17;
    padding: 8px;
    user-select: none;

    @at-root .disabled#{&} {
      color: $disabled-color;
    }

    @at-root .readonly#{&} {
      color: $disabled-color;
    }
  }

  @include round-input-content;
}

.valueRangeBox {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 5px;
  margin-top: 10px;
  box-shadow: 0 0.2rem 0.5rem rgba(69, 77, 93, 0.3);
  background-color: #fff;

  font-family: $font-family;
  color: $text-color-primary-variant-dark;

  .error {
    @include flex-center;
    flex: 0 0 auto;
    color: red;
    background-color: transparent;
    font-family: $font-family;
    font-size: $font-size * 0.92;
    font-weight: 300;
    line-height: $font-size * 1.17;
    padding: 8px;
    user-select: none;
  }

  h3 {
    font-size: 14px;
    padding: 10px 0px;
    text-align: center;
  }

  .inputs {
    @include flex-center;
    flex-direction: row;

    span {
      font-size: 12px;
    }

    .inputContainer {
      padding: 0;

      .textInput {
        align-self: center;
        display: flex;
        color: #5d5a71;
        background-color: #f7f8fc;
        width: 80px;
        border-radius: 6px;
        margin: 5px 10px;

        .input {
          @include input-box;
        }

        @include round-input-content;
      }
    }
  }
}
