@import 'theme';
@import 'helpers';

.cardsContainer {
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}

.executedModal {
  padding: 1rem;
  font-family: $font-family;
}
