@import 'theme';
@import 'helpers';

.card {
  color: $text-color-primary-variant-dark;
  background-color: $background-color;
  font-size: 14px;
  padding: 20px 31px 10px 21px;
  width: fit-content;
  margin-top: 10px;
  max-width: 600px;
  min-height: 90px;
  height: auto;
  border-radius: 12px;
  height: 160px;

  font-family: $font-family;
  font-weight: $font-weight-normal;
  position: relative;

  transition: all 0.2s ease-in-out;

  &.large {
    height: 190px;
  }

  &.clickable {
    cursor: pointer;
    &:hover {
      background-color: $table-hover-modern-color;
      box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 10px;
  }
}
