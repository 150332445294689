@import 'theme';
@import 'helpers';

.grid {
  display: flex;
  align-items: center;

  .children {
    display: flex;
    flex-direction: column;

    .child {
      display: flex;
      align-items: center;
    }
  }

  .container {
    display: flex;

    &:first-child:last-child {
      flex: 1;
    }

    > .parent {
      display: flex;
      flex-direction: column;

      > .fill {
        display: flex;
        flex: 1;

        > .wrapper {
          display: flex;
          margin: auto;
        }
      }
    }

    > .children {
      display: flex;
      flex-direction: column;

      > div:first-child {
        flex: 1;
      }

      > .fill {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
    }

    .offset {
      display: flex;
    }
  }

  .button {
    display: flex;

    > .wrapper {
      display: flex;
      margin: auto 0;
    }
  }
}
