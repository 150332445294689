@import 'theme';
@import 'helpers';

.counterpartCard {
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  color: $text-color-light-gray;

  font-size: 14px;
  font-weight: 600;

  row-gap: 2rem;

  .header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    font-size: 20px;
    font-weight: 600;
    color: $text-color-primary-variant-dark;
    margin: 0;
  }

  .graphsArea {
    display: flex;
    gap: 2rem;

    .graph {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        margin-top: 2px;
        font-weight: 400;
        font-size: 16px;
        color: #1d2125;
      }

      p {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        opacity: 0.8;
        text-align: center;
        min-width: 220px;
      }
    }
  }
}
