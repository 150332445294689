@import 'helpers';

.message {
  @include flex-center;
  min-height: 50px;

  border-radius: $input-border-radius;
  background-color: mix($primary-color, #fff, 10%);

  font-family: $font-family;
  font-size: $font-size;
  font-weight: 500;
  color: darken($primary-color, 10%);

  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &.visible {
    opacity: 1;

    span {
      opacity: 1;
    }
  }

  &.hidden {
    opacity: 0;

    span {
      opacity: 0;
    }
  }
}
