@import 'helpers';
@import 'theme';

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.25rem;

  font-family: $font-family;
  color: $text-color-light-gray;
  font-size: 16px;

  strong {
    font-size: 16px;
    font-weight: bold;
    color: $text-color-primary-variant-dark;
  }
}
