@import 'helpers';

.circularProgress {
  @include flex-center;
  position: relative;
  overflow: hidden;

  .legend {
    position: absolute;
    color: #000000;
    top: 45%;
    strong {
      font-weight: 600;
      font-size: 37px;

      &.zero {
        opacity: 0.2;
      }
    }
    span {
      opacity: 0.2;
    }
  }
  svg {
    transform: rotate(180deg);

    circle {
      stroke-width: 20.37px;
      stroke-dashoffset: 0;
      opacity: 0.2;
      fill: transparent;
      transition: stroke-dashoffset 0.9s ease;

      &.bar {
        opacity: 1;
      }
    }
  }
}
