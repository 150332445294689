@import 'theme';
@import 'helpers';

.hexagonGrid {
  display: inline-flex;
  flex-direction: column;

  .row {
    display: flex;
  }

  .hexagon {
    display: flex;
    position: relative;
    color: mix($primary-color, #fff, 8%);
    transition: color 0.3s;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: var(--hex-spacing);
    }

    &:hover {
      color: $primary-color;

      .content {
        color: $text-color-primary;
      }
    }

    .content {
      @include flex-center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: $section-fg-color;
    }
  }
}
