@import 'theme';

.summary {
  margin-top: 30px;

  h4 {
    margin-top: 30px;
    margin-bottom: 5px;
    font-family: $font-family;
    color: $system-dark-gray;
    font-weight: 400;
    font-size: 14px;

    padding-left: 10px;
  }

  table {
    font-family: $font-family;
    text-align: center;
    color: $system-black;
    background-color: $table-bg-color;
    border-collapse: collapse;
    border-spacing: 0;
    width: 95%;

    td:nth-child(1) {
      text-align: left;
      padding-left: 10px;
    }

    th:nth-child(1) {
      text-align: left;
      padding-left: 10px;
    }

    tbody {
      border-top: 1px solid $system-light-gray;
      border-bottom: 1px solid $system-light-gray;

      td {
        border-bottom: 1px solid $system-light-gray;
        color: $system-dark-gray;
        font-weight: 400;
      }

      > tr {
        border-bottom: 1.5px solid #d9d5ec !important;
        border-top: 1.5px solid #d9d5ec !important;
      }
    }

    tr {
      height: 40px;
    }
  }
}
