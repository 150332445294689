@import 'helpers';
@import 'theme';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.container {
  height: 70vh;
  position: relative;

  display: flex;
  flex-direction: column;

  .element {
    width: 100%;
    height: 20px;
    background-color: #80808014;
    position: relative;
  }

  .button {
    margin: 5px 0px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-top: 1px solid #d9d5ec;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 27px;
    border-bottom: 1px solid #d9d5ec;
    margin-bottom: 15px;

    span {
      color: #252f40;
      font-size: 18px;
      font-weight: 600;
      font-family: $font-family;
    }
  }
}
