@import 'theme.scss';

.headerMessage {
  border-radius: 5px;
  width: fit-content;
  height: 43px;
  display: inline-flex;
  position: absolute;
  animation: slide-up 0.4s ease;
  margin-right: 1%;

  .errorIcon {
    color: $system-white;
    margin-top: 0px;
    margin-left: 5px;
    display: flex;
  }

  .warnIcon {
    color: $warning-color-medium;
    margin-top: 0px;
    margin-left: 5px;
    display: flex;
    font-size: 30px;
  }

  .successIcon {
    margin-top: 0px;
    margin-left: 10px;
    display: flex;
    font-size: 15px;
    color: white;
  }

  .content {
    font-family: $font-family;
    font-size: $font-size;
    margin-left: 5px;
    width: 330px;
    text-align: justify;
    display: inline-flex;
    align-items: center;
  }
}

.dangerColor {
  background-color: $danger-color;
  color: $system-white;
}

.warningColor {
  background-color: $warning-color;
  color: $regular-color;
}

.successColor {
  background-color: $success-color;
  color: $system-white;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
