@import 'helpers';

.notification {
  display: flex;
  border-bottom: 1px solid $system-light-gray;
  font-family: $font-family;
  padding: 10px 0;
  margin-right: $active-scroll-bar-width;
  cursor: pointer;

  table {
    tr:not(:first-child) {
      td {
        padding: 0.25rem 0;
      }
    }
  }

  &.selected {
    background-color: lighten(saturate(adjust-hue($primary-color, 2), 0.4), 42.55);
  }

  &:last-child {
    border-bottom: none;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #25213b;
  }

  .type {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    color: #25213b;
  }

  .subtitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #5d5a71;
  }

  .date {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
    color: #696a6c;
  }

  .badge {
    position: relative;
    margin-left: 20px;
    padding-right: 10px;

    span {
      position: absolute;
      background-color: #007aff;
      height: 8px;
      width: 8px;
      border-radius: 4px;
      top: -4px;
      left: -8px;
    }
  }

  &.readonly {
    table {
      opacity: 0.5;
    }
  }
}
