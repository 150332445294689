@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.content {
  display: flex;
  flex-direction: column;
  font-family: $font-family;

  .backButton {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
  }

  .uploadedDocuments {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 700px;
    margin-top: 0.25rem;
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.25rem;
    }

    strong {
      text-transform: uppercase;
      margin: 0.25rem 0;
    }

    .documentsContent {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;

      .documentsTitle {
        font-weight: 600;
        text-transform: uppercase;
      }

      .documents {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.25rem;

        .document {
          display: flex;
          gap: 0.25rem;
          &:hover {
            text-decoration: underline;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.modalContent {
  max-height: 80vh;
  overflow: scroll;
  overflow-x: hidden;

  .avaliacao {
    font-family: $font-family;

    .content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .titulo {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.25rem;
      }

      .item {
        display: flex;
        flex-direction: column;

        .pergunta {
          text-transform: capitalize;
          font-weight: bold;
        }

        .resposta {
          color: $system-text-gray;
          word-break: break-all;
        }
      }
    }
  }
}

.popup {
  p {
    font-family: $font-family;
  }
}

.rhModal {
  padding: 0px 30px;
}

@include modal-popup;

@media (min-width: 1000px) and (max-width: 1500px) {
  .avaliacao {
    zoom: 0.8;
  }
}

@media (min-width: 1501px) and (max-width: 1920px) {
  .avaliacao {
    zoom: 0.8;
  }
}
