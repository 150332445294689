@import 'helpers';
@import 'theme';

@include tippy-popup;

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  max-height: 150px;
  position: relative;
  width: auto;

  .order {
    @include flex-center;
    font-size: $font-size;
    background-color: white;
    border: 1px solid #37a64a;
    padding: 2px 8px;
    color: #37a64a;
    font-weight: $font-weight-medium;
    border-radius: 22px;
    transition: all 0.3s ease-in-out;

    user-select: none;

    cursor: pointer;

    margin-top: 20px;

    &:hover {
      opacity: 0.8;
    }

    .label {
      margin-left: 5px;
    }
  }

  .graph {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
  }
}

.deleted {
  opacity: 0.5;
}
