@import './Activity.module.scss';

.activity {
  @include activity;
  background-color: #eceff1;

  span {
    margin: 0 10px;
    color: #607d8b;
  }
}
