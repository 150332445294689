@import 'helpers';
@import 'theme';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 23px 0px 30px;

  font-family: $font-family;
  color: $text-color-light-gray;

  .side {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 2rem;
    .legend {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 1rem;
      width: 100%;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    strong {
      font-size: 16px;
      font-weight: bold;
      color: $text-color-primary-variant-dark;
    }
  }

  .biggerLegend {
    width: 45%;
  }
}
