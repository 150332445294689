@import 'theme';
@import 'helpers';

.card {
  @include card;
  width: fit-content;

  padding: 22px 15px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.5rem;

    .title {
      width: fit-content;
      height: fit-content;
      padding: 0 10px;
      font-family: $font-family;
      font-weight: $font-weight-normal;
      font-size: $font-size + 2px;
      color: $text-color-primary-variant-dark;
      padding-bottom: 7px;
      text-align: center;
      border-bottom: 2px solid $text-color-primary-variant-dark;
    }
  }

  .body {
    @include flex-center;
  }
}
