@import 'helpers';
@import 'theme';

.expenseDetail {
  display: flex;
  flex-direction: column;
  row-gap: 45px;

  .header {
    display: flex;
    gap: 7px;
    align-items: stretch;
    flex-direction: row;
  }
  .back {
    display: flex;

    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
  }
}
