@import 'theme';

.financial {
  h4 {
    color: $text-color-light-gray;
    font-weight: 600;
    margin: 20px 0;

    padding-left: 10px;
  }

  .schedule {
    font-family: $font-family;

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.4rem;

      .projectValue {
        color: $text-color-primary-variant-light;
      }

      .pill {
        display: inline-flex;
        background-color: rgba(63, 199, 92, 0.51);
        color: $system-dark-green-variant;
        font-size: 16px;
        align-items: center;
        padding: 5px 10px;
        border-radius: 39px;

        :first-child {
          font-size: 12px;
          margin-right: 6px;
          font-weight: 600;
        }
        :last-child {
          font-weight: 900;
        }
      }
    }

    main {
      margin-top: 0.75rem;
    }

    main > div > div > div {
      padding-right: 0.75rem;
    }
  }
}
