@import 'helpers';

.monitor {
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 500;
  padding-left: 20px;
  position: relative;
  background: white;

  .loader {
    position: absolute;
    right: 5%;
    top: 50%;
  }
  .title {
    color: $text-color-light-gray;
    text-transform: uppercase;
    margin-bottom: 0px;
    user-select: none;
  }

  .content {
    display: inline-flex;
    align-items: center;

    .options {
      width: 16vw;
    }

    > span {
      @include flex-center;
      width: 5%;
      height: $input-height;
      color: #000000;
      font-size: 12px;
      font-weight: 600;
      margin-top: 8px;
    }

    .progress {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: $input-height;
      margin-top: 8px;
      border-radius: 9px;
      align-items: center;
      padding: 4px 15px;
      > div {
        margin-right: 12px;
      }
      span {
        font-size: 25px;
        font-weight: 700;

        > span {
          margin-left: 2px;
          font-size: 14px;
          opacity: 0.2;
        }
      }
    }
  }
}
