@import 'helpers';

.login {
  display: flex;
  width: 100%;
  height: 100%;

  background-image: linear-gradient(
    45deg,
    $primary-color 0%,
    $primary-color 50%,
    #ecf4ef 50%,
    #eaf3ed 100%
  );

  > div {
    display: flex;
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;

    box-shadow: 0 0 40px rgba(100, 100, 100, 0.2);
  }

  .formContainer {
    @include flex-center;
    flex-direction: column;
    flex: 1;
    padding: 20px 40px;
    height: 580px;
    opacity: 0;
    animation: fadeIn 0.2s ease-out 0.2s forwards;

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 800px;
      overflow: hidden;
      margin: auto;

      .success {
        @include flex-center;
        flex: 1;
        margin: 40px;
        flex-direction: column;
        color: $primary-color;

        span {
          font-family: $font-family;
          font-weight: 500;

          &:first-of-type {
            font-size: 26px;
            color: $primary-color;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 1px;
            margin: 20px;
          }

          &:last-of-type {
            font-size: 15px;
            color: $regular-color;
            width: 340px;
            text-align: center;
          }
        }
      }

      & > div {
        width: 100%;
      }

      .logos {
        @include flex-center;

        img {
          height: 40px;
        }

        span {
          width: 1px;
          height: 60%;
          margin: 0 15px;
          background-color: #0185d7;
        }
      }

      .card {
        padding: 10px;

        h2 {
          @include default-header;
        }

        .container {
          width: 100%;
          height: 100%;

          section {
            font-family: 'Open Sans', serif;
            font-style: normal;
            h3 {
              color: #5d5a71;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.05em;
              text-transform: uppercase;
            }
          }

          section + section {
            margin-top: 20px;
          }
        }
      }
    }

    .footer {
      font-family: $font-family;
      font-size: 11px;
      font-weight: 500;

      color: $regular-color;
      width: 320px;
      text-align: center;
      background-color: transparent;
    }
  }

  .banner {
    font-family: $font-family;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 40px;

    position: relative;
    flex: 0 0 auto;
    width: 440px;

    background-image: linear-gradient(
      to bottom,
      lighten(desaturate(adjust-hue($primary-color, 3), 5.81), 12.75),
      darken(saturate($primary-color, 4.89), 7.65)
    );

    .header {
      position: relative;
      padding-left: 17px;
      margin: 7.755% 5.51%;
      color: #fff;

      &::before {
        content: ' ';
        position: absolute;
        width: 5px;
        left: 0;
        top: 5.5px;
        background-color: #fff;
        animation: slide 0.2s ease-out forwards;
      }

      div {
        opacity: 0;
        width: 420px;
        font-size: 22px;
        font-weight: 300;
        animation: fadeIn 0.2s ease-out 0.2s forwards;

        &:last-child {
          margin-top: 5px;
          font-size: 30px;
          font-weight: 700;
        }
      }

      @keyframes slide {
        0% {
          height: 0;
        }
        100% {
          height: 93px;
        }
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 103.396%;
    }
  }
}
