@import 'helpers.scss';

.table {
  margin-bottom: 20px;

  max-width: 800px;

  .headers {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      color: $text-color-light-gray;
      font-weight: 600;
      margin: 20px 0;

      padding-left: 10px;
    }

    .restore {
      padding-block: 10px;
      .label {
        @include flex-center;
        font-family: $font-family;
        font-size: 12px;
        text-align: center;
        color: $system-black;
      }
    }
  }

  thead {
    th {
      text-align: center;
    }
    th:nth-child(1) {
      text-align: center;
      width: 5%;
    }
    th:nth-child(2) {
      text-align: center;
      width: 5%;
    }
  }

  tbody {
    tr {
      position: relative;
      height: 100%;
    }
    td {
      text-align: center;

      .addData {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 150px;
        background-color: #f4f4f4;
        border-radius: 10px;
        padding: 7px 3px;
        color: $system-text-gray;
        border: 1px dashed $system-text-gray;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: $background-color;
        }
      }
    }
    td:nth-child(1) {
      text-align: center;
      width: 5%;
    }
    td:nth-child(2),
    td:nth-child(3) {
      text-align: center;
      width: 40%;
    }

    td:nth-child(1).mes {
      padding: 0;
    }
  }
}
