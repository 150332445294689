@import 'helpers';

$border-width: 2px;

:export {
  borderWidth: $border-width;
}

.dropdown {
  display: flex;
  $border-radius: 4px;
  overflow: hidden;

  cursor: pointer;
  outline: none;
  border-radius: $border-radius;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 5px;
  max-height: 240px;

  .content {
    display: flex;
    flex-direction: column;

    .option {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      user-select: none;
      outline: none;

      > * {
        outline: none;
      }

      &:first-child {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba($primary-color, 0.1);
      }

      &:hover {
        background-color: rgba($primary-color, 0.15);
      }
    }
  }
}
