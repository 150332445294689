@import 'helpers';
@import 'theme';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: $font-family;
  font-weight: $font-weight-normal;
  font-size: 12px;
  line-height: 20px;
  margin: 0.5em 0;

  .label {
    font-weight: $font-weight-medium;
    color: $text-color-light-gray;
    margin-right: 0.3rem;
  }

  .whiteTheme {
    color: #fff;
  }

  .text {
    font-weight: 600;
    color: $text-color-primary-variant-dark;
    max-width: 22.5rem;
  }

  .whiteThemeText {
    color: #fff;
  }
}
