@import 'helpers';

.adornmentError {
  color: $error-color;
  position: relative;
  margin-top: -9px;
}

.adornmentWarn {
  color: $warning-color-medium;
  position: relative;
  margin-top: -9px;
}

.resetButton {
  width: 30%;
  margin-top: 0.75rem;
}
