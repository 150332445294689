@import 'theme';

.readOnlyTable {
  background-color: #ffffff;
  padding: 29px 25px;
  width: 700px;

  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  li {
    list-style: none;

    h3 {
      font-weight: 700;
      margin: 0;
      font-size: 18px;
      padding-top: 10px;
    }
    table {
      margin: 20px 0px 0px;
      width: 100%;

      th {
        text-transform: uppercase;
        color: #5d5a71;
      }
      td {
        padding: 10px 0px;
        color: #333333;
        font-size: 14px;

        span {
          color: $system-text-gray;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
