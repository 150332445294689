@import 'theme';
@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.actionItem {
  position: relative;

  .actionContainer {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding-left: 10px;
    margin: 0;
    background-color: transparent !important;

    visibility: hidden;
    pointer-events: none;

    opacity: 0;
    transition: opacity 0.4s ease-in;

    > div {
      display: flex;
      align-items: center;
      height: 100%;
      background-color: $table-hover-modern-color;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    > span {
      width: 60px;
      height: 100%;
      background: linear-gradient(
        to right,
        $table-hover-modern-color 0%,
        transparentize($color: $table-hover-modern-color, $amount: 1) 100%
      );
    }
  }

  &:hover {
    td:not(.actionContainer) {
      background-color: $table-hover-modern-color !important;
    }
    .actionContainer {
      pointer-events: visible;
      visibility: visible;
      opacity: 1;
    }
  }
}

.hoverable {
  &:hover {
    td:not(.actionContainer) {
      background-color: $table-hover-modern-color !important;
    }
  }
}
