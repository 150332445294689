@import 'helpers';
@import 'theme';

@include tippy-popup;

.datePicker {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  height: $input-height;
  outline: none;
  text-transform: none !important;

  .icon {
    @include flex-center;
    flex-shrink: 0;

    font-size: 14px;
    color: #686868;
    margin-left: 1em;
    align-items: center;
  }
  .iconRemove {
    @include flex-center;
    flex-shrink: 0;

    font-size: 12px;
    color: #686868;
    align-items: center;
  }

  &.active {
    .icon {
      color: #000000;
    }
  }

  .input {
    @include input-box;
    @at-root .disabled#{&} {
      color: $disabled-color;
      font-weight: 300;
    }

    @at-root .readonly#{&} {
      color: darken($text-color-primary, 33.33);
    }

    .content {
      display: flex;
      position: relative;
      align-items: center;
      flex: 1 1 0%;
      flex-wrap: wrap;
      overflow: hidden;
      box-sizing: border-box;
      transition: all 0.2s ease-in-out;

      &.placeholder {
        font-size: 12px;
        color: darken($text-color-primary, 33.33);
      }

      &.range {
        display: flex;
        font-size: 12px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }

    .dateInput {
      display: flex;
      align-items: center;
      width: 100px;

      .inputDate,
      .inputYear {
        text-align: center;
        background-color: transparent;
        border: none;
        outline: none;
        color: $system-black;
        font-size: 12px;
        width: 100%;
        &.disabled {
          opacity: 0.4;
        }
      }

      .inputDate::-webkit-inner-spin-button,
      .inputYear::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .inputDate::-webkit-outer-spin-button,
      .inputYear::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  @include round-input-content;

  @media screen and (max-width: 1300px) {
    .input {
      padding: 8px;
      .content {
        font-size: 10px !important;
      }
    }
  }
}
