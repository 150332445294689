@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.dialog {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  user-select: none;
  align-items: center;
  width: 100%;
  background-color: #fff;

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 30px;

    .icon {
      margin: 42px 0 30px 0;
      font-size: 74.72px;
    }

    .title {
      width: 100%;
      text-align: center;
      padding-top: 30px;
      flex: 1;
      font-size: $font-size * 1.57;
      line-height: $font-size * 1.57;
      font-family: $font-family;
      color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);
      font-weight: 600;
      font-style: normal;
      border-top: 1px solid $divider-color;

      .centeredTitle {
        @include flex-center;
        width: 95%;
        line-height: 1.1;
        margin: 0 auto;
      }
    }

    > .message {
      @include flex-center;
      @include break-word;

      font-family: $font-family;
      font-weight: 500;
      font-size: $font-size * 1.3;
      line-height: 1.5;
      color: $regular-color;
      text-align: center;
      padding: 5px 20px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    color: #fff;
    background-color: $section-bg-color;
    width: 100%;

    > .title {
      display: flex;
      align-items: center;
      column-gap: 8px;
      flex: 1;
      font-family: $font-family;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: -0.553191px;

      span {
        color: $text-color-primary-variant-dark;
      }
      > div {
        height: 20px;
        > svg {
          color: #d0d1d2;
        }
      }

      align-items: center;
      column-gap: 8px;
      @include break-word;

      box-sizing: border-box;
      padding: 30px 32px 0px;
    }

    > .message {
      font-family: $font-family;
      font-weight: 500;
      font-size: $font-size * 1.3;
      line-height: 1.5;
      color: $regular-color;
      text-align: center;
      padding: 15px 20px;
    }
  }

  .closeButton {
    position: absolute;
    top: 28px;
    right: 32px;

    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center;

    color: $system-black;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    > span {
      flex: 1;
      padding: 30px 0px;
      font-size: $font-size * 1.28;
      line-height: $font-size;
      font-family: $font-family;
      color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);
      font-weight: 600;
      font-style: normal;
      text-align: center;
    }
  }

  .footer {
    padding-bottom: 30px;
  }
}

:export {
  iconColor: lighten(saturate(adjust-hue($primary-color, 1), 5), 13.73);
}
