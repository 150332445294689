@import 'helpers';

.financial {
  display: flex;
  flex-direction: column;
  @include fill-parent-all;
  padding-top: 17px;
  box-sizing: border-box;
  margin-left: 15px;

  .table {
    flex: 1;
    @include fill-parent-width;
    padding-top: 20px;
    box-sizing: border-box;
  }
}

.adornmentError {
  color: $error-color;
  position: relative;
  margin-top: -9px;
}

.adornmentWarn {
  color: $warning-color-medium;
  position: relative;
  margin-top: -9px;
}
