.messages {
  margin: 20px 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  pointer-events: none;
  row-gap: 10px;
  align-items: flex-end;
  z-index: 100000;
  height: auto;
  transition: all 230ms cubic-bezier(0.21, 1.02, 0.73, 1);

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
  }
}
