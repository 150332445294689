@import 'helpers';
@import 'theme';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.item {
  padding: 0 2rem;
  font-family: $font-family;

  .sectionPath {
    font-size: 12px;
    color: $system-dark-gray;
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  p {
    max-width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.last {
      font-style: italic;
    }
  }
}
