@import 'helpers.scss';
@import 'theme';

.editor {
  display: flex;
  flex-direction: column;
  @include fill-parent-all;
  width: 900px;
  background-color: #fff;

  > label {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 600;
    padding: 11px 0;
    color: $system-text-gray;
    text-transform: uppercase;

    span {
      &.required {
        color: $obligated-red;
      }

      &.optional {
        color: $system-dark-gray;
      }
    }
  }
}

:global(.DraftEditor-root) {
  display: flex;
  @include fill-parent-all;

  min-height: 100%;

  font-family: $font-family;
  font-size: 16px;
  font-weight: 500;
  color: $system-black;

  box-sizing: border-box;

  $padding: 6px;
  padding: $padding;
  padding-top: 12px;

  :global(.public-DraftEditorPlaceholder-root) {
    top: $padding;
    left: $padding;
    right: $padding;
    bottom: $padding;
    width: auto !important;

    font-size: 16px;
    font-weight: 300;
  }

  :global(.DraftEditor-editorContainer) {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    width: auto;
    min-height: 100%;

    box-sizing: border-box;
  }

  figure {
    text-align: center;

    img {
      max-width: 500px;
      max-height: 500px;
    }
  }
}

.overlay {
  position: absolute;
  width: 100%;
  bottom: 0%;
  height: 25px;
  max-width: 90%;
  z-index: 3;
  padding: 5px 0;
  transform: translateY(6px);
  border-radius: $input-border-radius * 2;

  &.added {
    background: rgba(187, 235, 196, 0.19);
    border: 1px solid #2f6f3a;
  }

  &.modified {
    background: rgba(255, 245, 0, 0.19);
    border: 1px solid #fff500;
  }

  &.removed {
    border: 1px solid #f00;
    background: rgba(255, 0, 0, 0.19);
  }
}

.diff {
  font-family: $font-family;
  padding: 5px 0px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  border: 1px solid transparent;

  position: absolute;
  width: 100%;
  bottom: 0%;
  height: 25px;
  max-width: 90%;
  transform: translateY(6px);
  border-radius: $input-border-radius * 2;
}

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);
