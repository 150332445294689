@import 'helpers';

.body {
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: $error-color;

  &.noPadding {
    padding: 0;
  }
}

.error {
  font-family: $font-family;
  font-size: 11px;
  font-weight: 500;
  color: $error-color;
  position: absolute;
  display: flex;
  align-items: center;
  column-gap: 5px;
  animation: fadeIn 0.4s ease;
}
