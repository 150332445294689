@import 'theme';
@import 'helpers';

.rolesList {
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  color: $text-color-light-gray;

  font-size: 14px;
  font-weight: 600;

  .header {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    font-size: 20px;
    font-weight: 600;
    color: $text-color-primary-variant-dark;
  }

  .formPeriod {
    font-family: $font-family;
    color: $text-color-primary-variant-dark;
    font-weight: 600;
  }

  .addNewPeriod {
    @include flex-center;
    justify-content: center;
    margin: 20px 5px;
    width: 150px;
    background-color: #f4f4f4;
    font-weight: 400;
    font-size: 12px;
    border-radius: 10px;
    padding: 7px 3px;
    color: #686868;
    border: 1px dashed #686868;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $background-color;
    }
  }

  .formButtons {
    @include flex-center;
    padding: 15px 0;
  }
}

.teste {
  > div {
    align-items: center;
  }
}
