@import 'helpers';
@import 'theme';

.card {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
  margin-right: 20px;

  .headerTable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      font-family: $font-family;
      font-size: 18px;
    }

    span {
      cursor: pointer;
    }
  }
}

.cancelText {
  display: flex;
  color: $error-color;
  font-weight: bold;
  justify-content: center;
  cursor: pointer;
}

.modalContainer {
  padding: 4rem 6rem;
  font-family: $font-family;

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .warning {
      color: $warning-color-medium;
    }

    .buttons {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 25px 0;
    }
  }
}
