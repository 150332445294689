@import 'theme';

.summary {
  margin-top: 30px;

  table {
    font-family: $font-family;
    text-align: center;
    color: $system-black;
    background-color: $table-bg-color;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    td:nth-child(1) {
      text-align: left;
      padding-left: 10px;
    }

    td:nth-child(1) {
      width: 50%;
    }

    th:nth-child(1) {
      text-align: left;
      padding-left: 10px;
    }

    tbody {
      border-top: 1px solid $system-light-gray;
      border-bottom: 1px solid $system-light-gray;

      td {
        border-bottom: 1px solid $system-light-gray;
        color: $system-dark-gray;
        font-weight: 400;
        font-family: $font-family;
      }

      > tr:nth-child(odd) {
        background-color: $system-light-gray;
      }

      > tr {
        border-bottom: 1.5px solid #d9d5ec !important;
        border-top: 1.5px solid #d9d5ec !important;
      }
    }

    tr {
      height: 40px;
      font-family: $font-family;
    }

    .labelKeys {
      font-weight: 600;
      color: $system-black;
    }
  }
}
