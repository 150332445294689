.hidden {
  display: flex;
  flex-direction: column;
  position: relative;

  --height2: 100px;

  .wrapper {
    display: flex;
    position: absolute;
    overflow: hidden;

    &.expanding {
      animation: expand 0.3s ease-out forwards;
    }

    &.collapsing {
      animation: collapse 0.3s ease-in forwards;
    }
  }

  @keyframes expand {
    0% {
      height: 0;
      opacity: 0;
    }
    60% {
      height: var(--height);
      opacity: 0;
    }
    100% {
      height: var(--height);
      opacity: 1;
    }
  }

  @keyframes collapse {
    0% {
      height: var(--height);
      opacity: 1;
    }
    40% {
      height: var(--height);
      opacity: 0;
    }
    100% {
      height: 0;
      opacity: 0;
    }
  }
}
