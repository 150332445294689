@import 'helpers';

.peopleView {
  display: flex;
  height: 100%;

  flex-direction: column;

  font-family: $font-family;
  color: $text-color-primary-variant-dark;

  .peopleViewHeader {
    display: flex;
    justify-items: center;
    flex-direction: row;
    padding-bottom: 20px;
    margin-bottom: 28px;

    h2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: -0.387234px;
      opacity: 0.5;
      margin: 0;
      margin-bottom: 8px;
    }
    h1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: -0.387234px;
      margin: 0;
    }

    .infoButtons {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-left: 45px;
    }
  }
}
