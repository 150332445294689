@import 'helpers';
@import 'theme';

.history {
  position: absolute;
  height: calc(100% - 100px);
  width: fit-content;
  background-color: #fff;
  right: 0;
  z-index: 500;
  transform: translateX(100%) translateY(-32px);
  transition: transform 0.4s ease;
  padding: 0.5em 0.5em 0.5em;

  .items {
    display: flex;
    flex-direction: column;

    row-gap: 1rem;
    padding: 3rem 0;
  }

  &.visible {
    transform: translateX(0) translateY(-32px);
    animation: appear;
  }

  &.hidden {
    transform: translateX(100%) translateY(-32px);
    animation: disappear;
  }
}

@keyframes appear {
  0% {
    transform: translateX(100%) translateY(-32px);
  }
  100% {
    transform: translateX(0) translateY(-32px);
  }
}

@keyframes disappear {
  0% {
    transform: translateX(0) translateY(-32px);
  }
  100% {
    transform: translateX(100%) translateY(-32px);
  }
}
