@import 'helpers.scss';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.table {
  margin-bottom: 20px;

  h4 {
    color: $text-color-light-gray;
    font-weight: 600;
    margin: 20px 0;
  }

  thead {
    th {
      text-align: center;
    }
    th:nth-child(1) {
      text-align: center;
      width: 5%;
    }
    th:nth-child(2) {
      text-align: center;
      width: 5%;
    }

    .payTableHead {
      text-align: right;
      padding-right: 8px;
    }
  }

  tbody {
    tr {
      position: relative;
      height: 100%;
    }
    td {
      text-align: center;

      .addData {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 150px;
        background-color: #f4f4f4;
        border-radius: 10px;
        padding: 7px 3px;
        color: $system-text-gray;
        border: 1px dashed $system-text-gray;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: $background-color;
        }
      }
    }
    td:nth-child(1) {
      text-align: center;
      width: 5%;
    }
    td:nth-child(2) {
      text-align: center;
      width: 5%;
    }

    td:nth-child(1).mes {
      padding: 0;
    }
  }

  .overlay {
    position: absolute;
    width: 51%;
    height: 100%;
    z-index: 3;
    left: 5rem;
    border-radius: $input-border-radius * 2;

    &.added {
      background: rgba(187, 235, 196, 0.19);
      border: 1px solid #2f6f3a;
    }

    &.modified {
      background: rgba(255, 245, 0, 0.19);
      border: 1px solid #fff500;
    }

    &.removed {
      border: 1px solid #f00;
      background: rgba(255, 0, 0, 0.19);
    }
  }

  .restore {
    display: flex;
    width: fit-content;
    flex-direction: row;
    cursor: pointer;
    color: black;

    p {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      /* identical to box height, or 200% */
      text-align: center;
      letter-spacing: -0.011em;
      margin-left: 5px;
      color: #333333;
    }
  }
}
