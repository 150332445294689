@import 'theme';

.dashboard {
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;
  min-width: 700px;
  min-height: 0;

  padding: 0 0px 0 0px;
  box-sizing: border-box;

  background-color: $background-color;

  .center {
    display: flex;
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
  }
}
