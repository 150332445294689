@import 'helpers';
@import 'theme';

@include tippy-popup;

.container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: auto;
  max-height: 100px;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-width: 52%;
    width: 100%;

    .status {
      padding-top: 20px;
      padding-bottom: 5px;
    }
  }

  .extras {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px 0;

    .graph {
      padding: 15px 0;
    }
    .order {
      @include flex-center;
      justify-content: space-between;
      width: 100%;
      max-width: 140px;
      font-size: $font-size;
      background-color: #e1e1e1;
      padding: 2px 8px;
      color: $system-black;
      font-weight: $font-weight-medium;
      border-radius: 20px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      user-select: none;

      :first-child {
        justify-self: flex-start;
      }

      &:hover {
        opacity: 0.8;
      }

      .label {
        margin-right: 20%;
      }
    }
  }
}
