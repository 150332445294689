@import 'helpers';

.register {
  display: flex;
  flex: 1;
  background-color: $background-color;

  .content {
    display: flex;
    flex: 1;
    height: 100vh;
    min-height: 0;
  }

  .success {
    @include flex-center;
    flex: 1;
    flex-direction: column;

    span {
      font-family: $font-family;
      font-weight: 500;

      &:first-of-type {
        font-size: 38px;
        color: $primary-color;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        margin: 20px;
      }

      &:last-of-type {
        font-size: 15px;
        color: $regular-color;
        width: 340px;
        text-align: center;
      }
    }
  }
}
