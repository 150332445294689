@import 'helpers';
@import 'theme';

.section {
  > :first-child {
    margin-bottom: 25px;
  }

  h4 {
    margin-top: 30px;
    margin-bottom: 5px;
    font-family: $font-family;
    color: $system-dark-gray;
    font-weight: 400;
    font-size: 14px;
  }
}
