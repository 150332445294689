@import 'helpers';
@import 'theme';

.container {
  display: flex;
  flex-direction: column;
  .user {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    font-family: $font-family;
    margin-bottom: 0.5rem;

    strong {
      color: #25213b;
      margin-left: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .cargo {
    margin: -10px 0px 0px 60px;

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;
    }
  }
}
