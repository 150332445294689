@import 'helpers';
@import 'theme';

.legendItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  font-size: 12px;
  font-weight: 400;

  span::before {
    content: '';
    display: inline-block;
    height: 11px;
    width: 22px;
    background-color: var(--color);
    margin-right: 8px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
