@import 'theme';

.content {
  margin-top: 20px;
  border-collapse: collapse;
  border-spacing: 0;
  thead {
    th {
      padding-block: 0.5rem;
    }

    tr > td {
      color: $system-black;
    }
  }
  tbody {
    border-top: 1rem solid transparent;

    .labelKeys {
      font-weight: 600;
      color: $system-black;
      width: 15%;
    }

    .value {
      width: 4%;
    }

    .percentage {
      width: 2%;
    }
  }
}

.alertMessage {
  top: -30;
}
