$if-green: #2f9e41;
$if-red: #cd191e;

$system-light-gray: #e4e4e4;
$system-inter-gray: #d0d0d0;
$system-medium-gray: #aaaaaa;
$system-dark-gray: #686868;
$system-black: #333333;
$system-white: #f7f8fc;

$primary-color: $if-green;
$secondary-color: $if-red;
$regular-color: #565656;
$info-color: #2196f3;
$success-color: #4caf50;
$warning-color: #ffeeda;
$warning-color-medium: #f2b73f;
$error-color: #e20f0f;
$danger-color: #e51616;
$disabled-color: #d0d0d0;

$text-color-light-gray: #67748e;

$text-color-primary: #fff;
$text-color-secondary: #fff;
$text-color-info: #fff;
$text-color-success: #fff;
$text-color-error: #fff;
$text-color-warning: #504222;
$text-color-danger: #fff;
$text-color-primary-variant-light: #6e6893;
$text-color-primary-variant-dark: #252f40;

$section-bg-color: #fff;
$divider-color: mix($primary-color, #fff, 20%);
$section-fg-color: darken($primary-color, 10%);

$font-family: 'Open Sans', sans-serif;
$font-size: 12px;
$font-weight-slim: 370;
$font-weight-medium: 400;
$font-weight-normal: 700;

$input-height: 34px;
$input-border-radius: 4px;
$button-spacing: 40px;
$row-spacing: 18px;

$table-bg-color: transparent;
$table-hover-color: #e7e8ec;
$table-border-color: #e8e8e8;
$table-hover-modern-color: #def9e9;

$select-text-hover-color: #f7f8fc;

$checklist-bg-color: #f6f6f8;

$active-scroll-bar-width: 12px;
$background-color: #f8f9fa;
$icon-color: #858c94;

$side-menu-transition-duration: 0.2s;

$general-light-green: #bbebc4;
$general-dark-green: #37a64a;

$system-dark-green-variant: #0b591c;

$system-text-gray: #5d5a71;

$obligated-red: #da1414;

$input-bg: #eeeeee;
