@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.versionControl {
  @include flex-center;
  height: 100%;
  font-family: $font-family;
  color: $system-black;

  .container {
    @include flex-center;
    flex-direction: column;
    font-size: 14px;

    height: 100%;
    .header {
      @include flex-center;
      flex-direction: column;
      row-gap: 1rem;

      h2 {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .body {
      @include flex-center;
      flex-direction: column;
      row-gap: 0.2rem;

      .updateAlert {
        color: $system-medium-gray;
        padding-top: 50px;
        @include flex-center;
        flex-direction: column;
        font-size: 12px;
        row-gap: 0.2rem;
      }
    }
  }
}

.footer {
  @extend .versionControl;
  font-family: $font-family;
  color: $system-white;
  font-size: 12px;
  padding: 5px;
}
