@import 'theme';

.percentProposalContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  min-width: 220px;
  max-width: fit-content;
  column-gap: 16px;
  width: 90%;
  height: 82px;
  padding: 16px;
  box-sizing: border-box;

  &.thin {
    padding: 7px 16px;
    width: 100%;
    height: fit-content;
    min-width: 250px;
    .wrapper {
      .label {
        font-weight: 600;
        font-size: 12px;
        line-height: 13px;
      }
      > div {
        p {
          font-weight: 700;
          font-size: 17px;
          color: #82d616;
          line-height: 21px;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 4px;

    .label {
      margin: 0;
      font-family: $font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;

      letter-spacing: -0.387234px;

      color: $text-color-light-gray;
    }

    > div {
      display: flex;
      align-items: center;

      p {
        font-family: 'Open Sans', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: -0.553191px;

        color: $text-color-primary-variant-dark;
      }

      .percent {
        margin-left: 10px;
        font-family: 'Open Sans', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        letter-spacing: -0.387234px;

        color: #82d616;
      }
    }
  }

  .percentLogo {
    width: 48px;
    height: 48px;
    color: white;
    background: linear-gradient(135deg, #44cf5c 0%, #067652 100%);
    border-radius: 8px;
    box-shadow: 0px 2px 4px -1px #00000012, 0px 4px 6px -1px #0000001f;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      box-shadow: 0px 2px 4px -1px #00000012, 0px 4px 6px -1px #0000001f;

      width: 24px;
      height: 24px;
    }
  }
}
