@import 'theme';
@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

@media (min-width: 600px) {
  .milestones {
    grid-template-columns: repeat(2, 476px);
  }
}

@media (min-width: 900px) {
  .milestones {
    grid-template-columns: repeat(3, 476px);
  }
}

.milestones {
  font-size: 12px;
  font-weight: 500;
  font-family: $font-family;

  margin-top: 20px;
  padding: 5px 20px 5px 0px;
  display: grid;

  gap: 32px 26px;

  .item {
    height: 447px;
    padding: 20px 28px;
    border: 1px solid $system-light-gray;
    box-sizing: border-box;
    position: relative;
    border-radius: 10px;

    @include tippy-popup($color: $text-color-light-gray, $background: #ffff);

    .deleteMilestone {
      display: flex;

      position: absolute;
      bottom: 0;
      margin-bottom: 20px;
      color: $danger-color;
      padding: 5px 5px;

      cursor: pointer;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: transparentize($color: $danger-color, $amount: 0.85);
      }
    }

    &.added {
      background: rgba(187, 235, 196, 0.19);
      border: 1px solid #2f6f3a;
    }

    &.removed {
      border: 1px solid #f00;
      background: rgba(255, 0, 0, 0.19);
    }
  }

  .title {
    display: flex;
    align-items: flex-end;
    user-select: none;
    margin-top: 25px;
    margin-bottom: 5px;

    font-family: $font-family;
    font-style: normal;
    font-size: $font-size;
    font-weight: 600;
    line-height: $font-size * 1.14;
    text-transform: uppercase;

    color: $system-text-gray;
  }

  .deliverables {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 2px 0;
      border-radius: 5px;

      &.added {
        background: rgba(187, 235, 196, 0.19);
        border: 1px solid #2f6f3a;
      }

      &.removed {
        border: 1px solid #f00;
        background: rgba(255, 0, 0, 0.19);
      }
    }
  }
  .newMilestone {
    color: $system-text-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 447px;
    padding: 20px 28px;
    border: 1px dashed #868686;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fafafa;
    transition: background-color 2s ease-in-out;

    :not(:first-child) {
      margin-left: 12px;
    }
    & {
      opacity: 0.5;
      align-items: center;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      background-color: $system-light-gray;
      & {
        opacity: 1;
      }
    }
  }
  .newDeliverable {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
    width: 150px;
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 7px 21px;
    color: $system-text-gray;
    border: 1px dashed $system-text-gray;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $background-color;
    }
  }
}

.icon {
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;

  @include flex-center;
  margin-right: 7px;

  font-size: 24px;
  color: $input-bg;
  border-radius: 3px;
  opacity: 1;
}
