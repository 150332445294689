@import 'theme.scss';

.badgeMessage {
  border-radius: 5px;
  display: flex;
  transition: all 2s ease-in-out;
  padding: 8px;
  align-items: center;
  flex: 1;
  font-weight: 400;
  column-gap: 14px;

  .errorIcon {
    color: $system-white;
    display: flex;
    font-size: 14px;
  }

  .warnIcon {
    color: $warning-color-medium;
    display: flex;
    font-size: 32px;
  }

  .successIcon {
    display: flex;
    font-size: 14px;
    color: white;
  }

  .content {
    font-family: 'Roboto';
    font-size: 13px;
    white-space: initial;
    text-align: justify;
    color: #686868;

    b {
      color: #858585;
      font-weight: 700;
    }
  }
}

.dangerColor {
  background-color: $danger-color;
  color: $system-white;
}

.warningColor {
  background-color: $warning-color;
  color: $regular-color;
}

.successColor {
  background-color: $success-color;
  color: $system-white;
}
