@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.graph {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  font-family: $font-family;

  box-sizing: border-box;

  .pieChart {
    @include flex-center;
    svg {
      animation: rotate 1.2s ease-in-out;

      path {
        opacity: 1;
        transition: 0.5s;
        animation: scale 0.9s ease-in-out;
        transform-origin: center center;

        &:hover,
        &.selected {
          opacity: 0.6;
          transform: scale(1.1);

          transform-origin: center center;
        }
      }
    }
  }
  .legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: max-content;
    height: var(--height);
    margin-left: 10px;
    & > * {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
