@import 'theme';

.container {
  font-family: $font-family;
  font-weight: 600;
  max-width: 45vw;
  h1 {
    padding: 25px 0;
    font-size: 16px;
    color: $text-color-primary-variant-dark;
  }
  h2 {
    font-size: 12px;
    color: $system-text-gray;
  }
  .bankContainer {
    .helper {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 5px;
      width: 100%;
      white-space: nowrap;
      > span {
        margin-top: 10px;
        font-weight: normal;
      }
    }
  }
}
