@import 'theme';
@import 'helpers';

.content {
  margin-bottom: 32px;

  .counter {
    font-family: $font-family;

    font-weight: 400;
    font-size: $font-size * 0.86;
    line-height: $font-size * 0.64;
    text-align: left;

    color: lighten($regular-color, 7.06);

    opacity: 0.3;
  }
}
