@import 'helpers.scss';

.toolBar {
  @include flex-center;
  position: relative;
  padding: 12px;

  .left {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200px;

    font-family: $font-family;
    font-size: 12px;
    font-weight: 400;
    color: $system-dark-gray;
    opacity: 0.6;
    padding: 6px;
    margin-top: 4px;
  }

  .button {
    @include flex-center;
    border-radius: 2px;

    width: 30px;
    height: 30px;
    margin: 1px;
    font-size: 20px;
    cursor: pointer;
    color: $system-black;

    &:hover {
      background-color: $system-light-gray;
    }

    &.selected {
      background-color: $primary-color;
      color: $system-white;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .divider {
    width: 10px;
    height: 100%;
  }
}
