@import 'helpers';

* {
  margin: 0;
  padding: 0;
}

.notFoundMessage {
  @include flex-center;
  flex-direction: column;
  flex: 1;
  padding: 25px;

  font-family: $font-family;
  font-weight: 500;

  .icon {
    @include flex-center;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  }
  h1 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.7);
    padding: 10px;
  }
  p {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    padding: 3px;
    max-width: 70%;
    text-align: center;
  }
}
