@import 'theme';
@import 'helpers';

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0ffdd;
  padding: 10px;
  border-radius: 55px;
  margin-inline: 32px;
  margin-block: 16px;

  font-family: $font-family;
  font-size: 14px;
  color: #333333;
  column-gap: 4px;

  strong {
    color: #0d7d00;
  }
}
