@import 'helpers';

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  @include fill-parent-height;
  padding: 0 15px 5px 32px;
  box-sizing: border-box;

  .wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    min-width: 0;
    overflow: hidden;
    box-sizing: border-box;
    font-size: $font-size;
  }
}
