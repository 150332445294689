@import 'helpers';

.treeList {
  display: flex;

  font-family: $font-family;
  font-size: $font-size * 1.035;
  font-weight: 400;
  color: $regular-color;

  @include fill-parent-all;

  > div {
    display: flex;
    flex-direction: column;

    &:first-child {
      width: 49%;
    }

    &:last-child {
      flex: 1;
    }

    .title {
      display: table;
      flex-shrink: 0;
      font-size: $font-size * 1.428;
      font-weight: 700;
      height: $font-size * 3.87;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        align-items: center;
        height: $font-size * 3.57;
        padding: 0 20px;

        border-top: 1px solid $table-border-color;
        transition: background-color 0.2s;

        cursor: pointer;

        &:first-child {
          border-color: transparent;
        }

        &.selected {
          background-color: $primary-color;
          color: $text-color-primary;
        }
      }
    }

    .container {
      display: flex;
      flex: 1;
      flex-direction: column;

      > div {
        display: flex;
      }
    }
  }
}

$popupBgColor: lighten(saturate(adjust-hue($primary-color, 15), 45.85), 55.49);

:export {
  popupBgColor: $popupBgColor;
}

.popup {
  display: flex;
  width: 100%;

  background-color: $popupBgColor !important;

  :global(.tippy-arrow) {
    color: $primary-color !important;
    width: 32px !important;
    height: 32px !important;

    &::before {
      left: -14px !important;
      border-width: 16px 16px 16px 0 !important;
    }
  }

  :global(.tippy-content) {
    padding: 0 !important;
  }

  &[data-reference-hidden] {
    opacity: 0 !important;
    visibility: hidden !important;
  }

  .contentList {
    display: flex;
    width: 100%;

    ul {
      margin: 0 12px;

      li {
        font-family: $font-family;
        font-size: $font-size * 1.035;
        font-weight: 400;

        color: $regular-color;
        border-top: 1px solid $primary-color;
      }
    }
  }
}
