@import 'helpers';

.switch {
  $width: $input-height * 1.52;

  display: inline-flex;
  position: relative;
  flex-shrink: 0;

  height: $input-height;

  z-index: 0;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  outline: none;

  .control {
    display: flex;
    height: 100%;
    width: $width;
    padding: 12px;
    box-sizing: border-box;
    outline: none;
  }

  .track {
    width: 100%;
    height: 100%;
    opacity: 0.38;
    z-index: -1;

    border-radius: 7px;
    background-color: lighten($disabled-color, 7.84);

    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .button {
    @include flex-center;

    position: absolute;
    top: 0;
    right: $width - $input-height;
    width: $input-height;
    height: $input-height;

    z-index: 1;
    border-radius: 50%;
    color: #888;

    @include hover-growing-shadow($color: #bbb);

    transition: right 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .thumb {
      width: 20px;
      height: 20px;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      background-color: lighten($regular-color, 32.94);
    }
  }

  .label {
    display: flex;
    align-items: center;

    font-size: $font-size;
    font-family: $font-family;
    font-weight: 300;

    color: $regular-color;
  }

  &.active {
    .track {
      background-color: $primary-color;
    }

    .button {
      right: 0;
      color: lighten(saturate(adjust-hue($primary-color, 2), 11.75), 34.51);
      @include hover-growing-shadow($color: $primary-color);

      .thumb {
        background-color: lighten(saturate(adjust-hue($primary-color, 1), 5), 13.73);
      }
    }
  }

  &:focus {
    .button > div::before {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;

    .track {
      background-color: $disabled-color;
    }

    .button {
      .thumb {
        background-color: $disabled-color;
      }
    }
  }
}
