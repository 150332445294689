@import 'theme';

.box {
  --dark-green: #0b591c;

  display: inline-flex;
  flex-direction: row;
  font-family: $font-family;
  box-sizing: border-box;
  align-items: flex-end;
  gap: 1rem;

  .legend {
    color: $text-color-light-gray;
    font-size: 14px;
  }
  .pill {
    display: inline-flex;
    background-color: rgba(63, 199, 92, 0.51);
    color: $system-dark-green-variant;
    font-size: 14px;
    align-items: center;
    padding: 3px 10px;
    border-radius: 39px;

    :first-child {
      font-size: 12px;
      margin-right: 6px;
      font-weight: 600;
    }
    :last-child {
      font-weight: 900;
    }
  }
}
