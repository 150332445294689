@import 'theme';

.infoCard {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  column-gap: 18px;
  padding: 0px 16px;
  box-sizing: border-box;
  width: fit-content;

  .info {
    display: flex;
    flex-direction: column;
    font-family: $font-family;
    font-size: 12px;
    line-height: 16px;
    color: $system-text-gray;
    row-gap: 3px;
    min-width: 180px;
    &.center {
      align-items: center;
    }
    &.right {
      align-items: flex-end;
    }

    span {
      text-transform: uppercase;
    }
    strong {
      font-size: 14px;
    }
  }
}
