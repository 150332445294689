@import 'helpers';

.card {
  h2 {
    @include default-header;
  }
  h3 {
    @include default-header;
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    text-transform: uppercase;
    margin: 0;
  }

  .documents {
    display: flex;
    flex-direction: column;

    > label {
      font-family: $font-family;
      font-size: $font-size;
      font-weight: 600;
      padding: 11px 0;
      color: $system-text-gray;
      text-transform: uppercase;

      span {
        &.required {
          color: $obligated-red;
        }

        &.optional {
          color: $system-dark-gray;
        }
      }
    }
  }
}

.container {
  font-family: $font-family;
  font-weight: 600;

  h1 {
    padding: 8px 0;
    font-size: 16px;
    color: $text-color-primary-variant-dark;
  }
  h2 {
    font-size: 12px;
    color: $system-text-gray;
  }
}
