@import 'helpers';

.dashboard {
  display: flex;
  @include fill-parent-all;

  flex-direction: column;
  align-items: flex-start;
  row-gap: 50px;
  margin-bottom: 1%;
  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
