@import 'theme';

.helper {
  position: relative;

  $helper-color: darken(saturate(adjust-hue($primary-color, 1), 13.65), 17.06);

  a {
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none;
    font-family: $font-family;
    font-size: 7.5px;
    font-weight: 500;
    letter-spacing: 0.07px;
    text-align: center;
    color: $helper-color;
    text-transform: uppercase;
    z-index: 1;

    &.disabled,
    &.readonly {
      pointer-events: none;
      color: $disabled-color;
    }
  }
}
