@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.sideMenu {
  display: flex;
  flex-direction: column;
  @include fill-parent-height;
  overflow: hidden;
  background-color: #fff;
  padding: 0 18px 25px 18px;
  box-sizing: border-box;

  $color: darken(saturate(adjust-hue($primary-color, -1), 3.92), 3.14);
  $color-selected: #fff;
  $descWidth: 152px;
  $duration: 0.3s;
  $radius: 10px;

  .trigger {
    @include flex-center;
    width: 56px;
    height: 40px;
    color: $primary-color;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      color: darken($primary-color, 10%);
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      border-radius: $radius;
      cursor: pointer;
      margin: 5px 0;
      user-select: none;
      transition: background-color ease-in-out 0.1s, margin-left ease 0.4s;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;

        .icon {
          @include flex-center;
          width: 56px;
          height: 56px;
          font-size: 24px;
          color: #78909c;
        }

        .description {
          font-family: $font-family;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0.44px;
          color: rgba(0, 0, 0, 0.87);

          max-width: 0;

          span {
            display: flex;
            width: $descWidth;
            box-sizing: border-box;

            transform: scale(0);
            opacity: 0;
          }

          .helper {
            font-size: 12px;
            @include ellipsis;
            max-width: calc(100% - 10px);
          }
        }
      }

      &.selected,
      &:hover {
        background-color: lighten(saturate(adjust-hue($primary-color, 1), 2), 40.73);

        a {
          .icon svg path,
          .icon {
            color: $color;
          }

          span {
            color: $regular-color;
          }
        }
      }

      &:hover {
        background-color: lighten(saturate(adjust-hue($primary-color, 1), 5), 50.73);
      }
    }
  }

  .divider {
    height: 2px;
    background: #e9e9e9;
    margin-bottom: 8px;
  }

  .footer {
    margin-top: auto;

    ul {
      li {
        margin: 0;
        border-radius: 0;

        a {
          .icon {
            height: 40px;
          }
        }

        &:first-child {
          border-top-left-radius: $radius;
          border-top-right-radius: $radius;
        }

        &:last-child {
          border-bottom-left-radius: $radius;
          border-bottom-right-radius: $radius;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $system-light-gray;
        }

        &.selected {
          background-color: $system-inter-gray;

          a {
            .icon svg path {
              fill: $system-dark-gray;
            }

            span {
              color: $system-dark-gray;
            }
          }
        }

        &:hover {
          background-color: $system-light-gray;

          a {
            .icon svg path {
              fill: $system-dark-gray;
            }

            span {
              color: $system-dark-gray;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      margin-top: 20px;
      max-width: 0;

      a {
        border-radius: $radius;
        background-color: #f7f9fa;
        font-family: $font-family;
        color: $regular-color;
        font-size: 14px;
        line-height: 20px;
        padding: 6px 15px;
        text-decoration: none;

        transform: scale(0);
        opacity: 0;
      }
    }
  }

  &.visible {
    li {
      .description {
        animation: expand $duration ease-in-out forwards;

        span {
          animation: fadeIn $duration ease-in-out forwards;
        }

        .helper {
          color: #5e6366;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .footer {
      .buttons {
        max-width: unset;
        animation: expand $duration ease-in-out;

        a {
          animation: fadeIn $duration ease-in-out $duration * 0.2 forwards;
        }
      }
    }
  }

  &.hidden {
    li {
      &.subMenu {
        margin-left: 0;
      }
      .description {
        animation: colapse $duration ease-in-out forwards;

        span {
          animation: fadeOut $duration ease-in-out forwards;
        }
      }
    }
  }

  @keyframes expand {
    0% {
      max-width: 0;
    }
    80%,
    100% {
      max-width: $descWidth;
    }
  }

  @keyframes colapse {
    0%,
    20% {
      max-width: $descWidth;
    }
    100% {
      max-width: 0;
    }
  }

  @keyframes fadeIn {
    0%,
    80% {
      opacity: 0;
      transform: scale(0);
    }
    81% {
      opacity: 0;
      transform: scale(1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    19% {
      opacity: 0;
      transform: scale(1);
    }
    20%,
    100% {
      opacity: 0;
      transform: scale(0);
    }
  }
}
