@import 'theme';

.loginForm {
  display: flex;
  flex-direction: column;
  height: 340px;

  .inputs {
    margin-top: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 151px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .buttons > div {
    width: 7rem;
    height: 2.5rem;
  }

  .link {
    margin-top: 1.25rem;
    font-family: $font-family;
    font-size: 0.875rem;
    font-weight: 400;
    color: $regular-color;
    text-align: center;

    &:first-child {
      opacity: 0.8;
    }

    &.forgot {
      font-family: $font-family;
      font-size: 12px;
      line-height: 16px;

      text-align: right;

      padding: 0;
      margin: 12px 9px 0 0;
    }

    a {
      text-decoration: none;
      outline: none;

      &:focus {
        text-decoration: underline;

        span {
          color: darken($primary-color, 20%);
        }
      }
    }

    span {
      &:last-child {
        font-weight: 700;
        color: #00ad43;
        margin-left: 5px;

        &:hover {
          color: darken($primary-color, 20%);
          text-decoration: underline;
        }
      }
    }
  }
}
