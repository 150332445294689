@import 'theme';
@import 'helpers';

.eap {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .eapTitle {
    font-family: $font-family;
    font-size: 14px;
    text-transform: uppercase;
  }
}
