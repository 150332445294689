@import 'helpers';
@import 'theme';

.conclusionPlanner {
  table {
    font-family: $font-family;
    color: $system-black;
    background-color: $table-bg-color;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    color: $system-text-gray;
    position: relative;

    thead {
      th,
      td {
        position: sticky;
        box-sizing: border-box;
        user-select: none;
        background-color: #fff;
      }
      tr {
        th {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          box-sizing: border-box;
          user-select: none;
          text-transform: uppercase;
          min-width: 60px;
          padding: 20px 0px;

          &:not(:first-child) {
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }
          }
          &:first-child {
            padding-left: 23px;
          }
        }

        td {
          position: sticky;
          font-weight: 500;
          text-align: center;
          padding: 7.75px 15px;
          box-sizing: border-box;
        }
      }
    }

    tbody {
      tr {
        &.resume {
          td {
            font-size: 13px;

            &:first-child {
              font-size: 14px;
              color: #25213b;
              font-weight: 700;
            }
          }
        }
        &:not(.resume) {
          &:nth-child(odd) {
            td {
              background-color: $input-bg;
            }
          }
        }
        &:not(.resume):nth-last-of-type(4) {
          td {
            border-bottom: none;
          }
        }
        &.resume:nth-last-of-type(3) {
          td {
            border-top: none;
            padding-top: 45px;
          }
        }

        td {
          color: $system-text-gray;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          box-sizing: border-box;

          border-top: 1px solid $system-light-gray;
          border-bottom: 1px solid $system-light-gray;
          background-color: transparent;

          &:first-child {
            overflow: auto;
            text-overflow: auto;
            padding: 1px 20px;
            text-align: left;
            padding-left: 23px;
          }

          &:nth-child(n + 3) {
            padding: 10px 5px;
            max-height: 60px;
          }
        }
      }
    }
  }
}
