@import 'helpers';
@import 'theme';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.documents {
  font-family: $font-family;
  color: $system-text-gray;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .documentsTitle {
    font-weight: 600;
    text-transform: uppercase;
  }

  .uploadedDocuments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.25rem;

    .document {
      display: flex;
      gap: 0.25rem;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.rowLabel {
  font-weight: 600;
  text-transform: uppercase;
  font-family: $font-family;
  color: $system-text-gray;
  padding: 10px;

  margin-top: 1rem;
  display: flex;
}
.modalContent {
  max-height: 80vh;
  overflow: scroll;
  overflow-x: hidden;

  .avaliacao {
    font-family: $font-family;

    .content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .titulo {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.25rem;
      }

      .item {
        display: flex;
        flex-direction: column;

        .pergunta {
          text-transform: capitalize;
          font-weight: bold;
        }

        .resposta {
          color: $system-text-gray;
          word-break: break-all;
        }
      }
    }
  }
}

.contentPopup {
  padding: 20px;
  p {
    font-family: $font-family;
  }
}

@include modal-popup;

@media (min-width: 1000px) and (max-width: 1500px) {
  .avaliacao {
    zoom: 0.8;
  }
}

@media (min-width: 1501px) and (max-width: 1920px) {
  .avaliacao {
    zoom: 0.8;
  }
}
