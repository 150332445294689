@import 'helpers.scss';
.container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-bottom: 64px;
  .header {
    display: flex;
    justify-content: space-between;
    padding-block: 10px;
    padding-right: 32px;
    font-family: $font-family;

    .titles {
      h2 {
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: -0.387234px;
        opacity: 0.5;
        margin: 0;
        margin-bottom: 0px;
      }
      h1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: -0.387234px;
        margin: 0;
      }
    }
  }

  .filter {
    min-width: 280px;
    padding-bottom: 16px;
  }
}
