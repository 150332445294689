@import 'helpers';
@import 'theme';

.iconButton {
  display: inline-flex;
  flex-shrink: 0;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  justify-content: center;

  $hover-color: $regular-color;

  > div {
    @include flex-center;
  }

  @mixin hover($color) {
    color: $color;

    &.square {
      @include hover-growing-shadow(100%, $color, 'square');
    }

    &.circle {
      @include hover-growing-shadow(100%, $color, 'circle');
    }

    &.overflow {
      &.square {
        @include hover-growing-shadow(150%, $color, 'square');
      }

      &.circle {
        @include hover-growing-shadow(150%, $color, 'circle');
      }

      overflow: visible;
    }
  }

  @include hover($regular-color);

  &.danger {
    @include hover($danger-color);
  }

  &.primary {
    @include hover($primary-color);
  }

  &.invert {
    @include hover($text-color-primary);
  }

  &.dark {
    @include hover(#000000);
  }

  &.disabled {
    color: $disabled-color;

    pointer-events: none;
    cursor: default;
  }
}

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);
