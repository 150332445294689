@import 'theme';

.section {
  display: flex;
  flex-direction: column;
  margin: 15px 0;

  .header {
    display: flex;
    align-items: center;
    min-height: $input-height;
    border-bottom: 3px solid $primary-color;

    span {
      font-family: $font-family;
      font-size: $font-size * 1.2;
      font-weight: 500;
      margin-right: 8px;
      color: $regular-color;
    }
  }

  .content {
    padding: 10px 30px 20px 30px;
    background-color: $section-bg-color;
  }
}
