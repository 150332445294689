@import 'helpers';

.accordion {
  display: flex;
  flex-direction: column;

  &:last-of-type {
    border-bottom: 1px solid mix($primary-color, #fff, 50%);
  }

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid mix($primary-color, #fff, 50%);
    cursor: pointer;
    user-select: none;
    outline: none;

    .icon {
      @include flex-center;
      color: $regular-color;
      flex-shrink: 0;
      padding: 5px;
      font-size: 15px;
      transition: transform 0.3s;

      &.visible {
        transform: rotate(-180deg);
      }
    }

    .title {
      flex: 1;
      font-family: $font-family;
      font-size: $font-size;
      font-weight: 700;
      color: darken($primary-color, 10%);
      text-transform: uppercase;
    }
  }
}

.grayAccordion {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-left: 1px solid #cdccd7;
  background: #f6f6f8;

  .grayHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    outline: none;

    .grayIcon {
      @include flex-center;
      color: $regular-color;
      flex-shrink: 0;
      padding: 5px;
      font-size: 15px;
      transition: transform 0.3s;

      &.grayVisible {
        transform: rotate(-180deg);
      }
    }

    .grayTitle {
      flex: 1;
      font-family: $font-family;
      font-size: $font-size;
      font-weight: 500;
      color: darken($text-color-light-gray, 10%);
      text-transform: uppercase;

      &.removed {
        color: rgba(255, 0, 0, 0.59);
      }

      &.added {
        color: rgba(98, 218, 120, 1);
      }

      &.modified {
        color: rgb(168, 156, 49);
      }
    }
  }
}
