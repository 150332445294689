@import 'helpers.scss';
@import 'consts.scss';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

$border-radius: 4px;
$border-width: 2px;
$height: 48px;
$margin: 2px;

:export {
  borderRadius: $border-radius;
  borderWidth: $border-width;
  height: $height;
  animDuration: $anim_duration;
}

@mixin activity {
  display: flex;
  align-items: center;
  position: relative;

  height: $height;
  margin: $margin 0;
  border-radius: $border-radius;
  box-sizing: border-box;

  span {
    @include ellipsis;
    flex: 1;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 400;
    color: #fff;
    padding: 14px 4px;
  }

  &.readonly {
    pointer-events: none;
  }
}

.activity {
  @include activity;

  transition: width $anim_duration $anim_func, margin-left $anim_duration $anim_func;

  > div {
    @include flex-center;

    flex-shrink: 0;
    width: 10px;
    height: 100%;

    &::before {
      content: '';
      width: 3px;
      height: 12px;
      background-color: transparentize(#fff, 0.5);
      border-radius: 5px;
      opacity: 0;
    }

    &:hover {
      &:first-of-type {
        cursor: w-resize;
      }

      &:last-of-type {
        cursor: e-resize;
      }
    }
  }

  &:hover {
    & > div::before {
      opacity: 1;
    }
  }

  span {
    transition: padding-right $anim_duration $anim_func;

    &:hover {
      cursor: move;
    }
  }

  svg {
    position: absolute;
    top: 0;

    .target {
      stroke: #000;
      stroke-width: $border-width;
      fill: none;
    }

    .shadow {
      stroke: none;
      fill: transparentize($color: #000000, $amount: 0.9);
    }
  }

  &.add {
    animation-name: grow;
    animation-timing-function: $anim_func;
    animation-duration: $anim_duration;
  }

  @keyframes grow {
    0% {
      margin-top: 0;
      margin-bottom: 0;
      height: 0;
      overflow: hidden;
    }
    100% {
      margin-top: $margin;
      margin-bottom: $margin;
      height: $height;
      overflow: visible;
    }
  }
}

.added {
  background: rgba(187, 235, 196, 0.19) !important;
  border: 1px solid #2f6f3a;
  pointer-events: all !important;

  span {
    color: black;
  }
}

.modified {
  background: rgba(255, 245, 0, 0.19) !important;
  border: 1px solid #fff500;
  pointer-events: all !important;

  span {
    color: black;
  }
}

.removed {
  border: 1px solid #f00;
  background: rgba(255, 0, 0, 0.19) !important;
  pointer-events: all !important;

  span {
    color: black;
  }
}

.actions {
  border-radius: $border-radius;
}
