@import 'helpers';
@import 'theme';

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: $font-family;
  font-weight: 600;
  font-size: 18px;

  .breadcrumb {
    color: $system-dark-gray;
    font-weight: 400;
    font-size: 12px;

    & span::after {
      content: '>';
      margin: 0 5px;
    }

    span:hover:not(:last-child) {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .footer {
    color: $system-black;
    font-weight: 700;
    font-size: 14px;
    margin: 0;
    max-width: 45vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
