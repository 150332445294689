@import 'theme';

.partsTable {
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto 27px 0;
  font-family: $font-family;
  width: 100%;

  td {
    font-weight: 400;
    font-size: $font-size;
    line-height: $font-size * 1.17;
    text-align: center;
    padding: 2px 8px 4px 8px;
    box-sizing: border-box;
  }

  thead {
    background-color: rgba(196, 196, 196, 0.49);
    color: $system-text-gray;
    text-transform: uppercase;

    tr {
      th {
        padding-top: 14px;
      }
      th,
      td {
        font-weight: 600;
        font-size: $font-size;
        line-height: $font-size * 1.17;
        text-align: center;
        box-sizing: border-box;
      }
    }

    th {
      &:first-child {
        border-top-left-radius: 12px;
      }
      &:last-child {
        border-top-right-radius: 12px;
      }
    }

    td {
      &:nth-child(odd) {
        width: 120px;
      }

      &:nth-child(even) {
        width: 100px;
      }
    }
  }

  tbody {
    td {
      padding: 5px;
      color: $system-medium-gray;

      div {
        padding: 10px 7px;
        background-color: $input-bg;
        border-radius: 6px;
      }

      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .loader {
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    div {
      max-width: 50px;
      align-items: flex-start;
      justify-content: flex-start;
      justify-items: flex-start;
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
}
