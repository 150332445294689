@import 'theme';
@import 'helpers';
.rolesList {
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  color: $text-color-light-gray;

  font-size: 14px;
  font-weight: 600;

  .header {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    strong {
      font-size: 16px;
      font-weight: bold;
      color: $text-color-primary-variant-dark;
    }
  }

  .grid {
    display: flex;
    gap: 17px;
    grid-row-gap: 0px;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media screen and (max-width: 1600px) {
  .rolesList {
    zoom: 0.9;
  }
}
