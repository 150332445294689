@import 'helpers';

.autoCompute {
  @include flex-center;

  flex: 1 1 auto;
  height: $input-height;
  min-width: 0;
  overflow: hidden;

  font-family: $font-family;
  font-size: $font-size;
  font-weight: 500;
  color: darken($text-color-primary, 33.33);

  box-sizing: border-box;

  border-radius: 6px;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.compact {
    background-color: #c6c6c6;
    color: $regular-color;
  }
  .adornment {
    @include flex-center;
    flex: 0 0 auto;
    color: black;
    background-color: transparent;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 400;
    line-height: $font-size * 1.17;
    padding: 8px;
    user-select: none;

    @at-root .disabled#{&} {
      color: $disabled-color;
    }

    @at-root .readonly#{&} {
      color: $disabled-color;
    }
  }

  .value {
    @include flex-center;
    flex: 0 0 auto;
    color: black;
    background-color: transparent;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 400;
    line-height: $font-size * 1.17;
    padding: 8px;
    user-select: none;

    @at-root .disabled#{&} {
      color: $disabled-color;
    }
  }
}
