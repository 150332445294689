@import 'theme';
@import 'helpers';

.corrections {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 9px;

    span {
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 11px;
      background-color: $system-light-gray;
      border-radius: 6px;
      padding: 11px 17px;
      color: darken($regular-color, 13.73);
    }
    i {
      margin: 0 5px;
    }
  }

  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 29px;

    .author {
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: lighten($regular-color, 7.06);
      margin: 4px;
    }

    .content {
      background: darken(saturate(adjust-hue($primary-color, 25), 4.05), 5.49);
      border-radius: 6px 6px 0px 6px;
      padding: 11px 19px;
      max-width: 47%;
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $system-white;

      & > p {
        margin: 0;
      }
    }
  }

  .invert {
    align-items: flex-start;
    margin-left: 15px;

    .content {
      background: darken($regular-color, 15.29);
      border-radius: 6px 6px 6px 0px;
    }
  }
}
