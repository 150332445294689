@import 'helpers';

.proposalEditor {
  display: contents;

  @include fill-parent-all;

  .errorAdornment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      align-items: center;
      border-radius: 9px;
      width: 16px;
      height: 16px;
      font-weight: 500;
      font-size: 10px;
      text-align: center;
      color: $system-white;

      animation: fadeIn 0.2s ease-in-out forwards;

      &:not(:first-child) {
        margin-left: 2px;
      }
    }

    .checkErrorUp {
      background-color: $danger-color;
    }

    .checkErrorDown {
      background-color: $warning-color-medium;
    }
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    @include fill-parent-height;
    box-sizing: border-box;
    min-width: 400px;

    margin-top: 0.75rem;

    .body {
      flex: 1;
      // @include fill-parent-width;
    }

    .filledWidthBody {
      @include fill-parent-width;
    }
  }
}
