@import 'helpers';

.homologacao {
  display: flex;
  flex-direction: column;
  @include fill-parent-all;
  overflow: hidden;

  min-width: 900px;
  max-width: 1500px;

  > .columns {
    display: flex;
    flex-grow: 1;

    @include fill-parent-width;
    @include card;
    overflow: hidden;

    padding: 10px 0;
    box-sizing: border-box;
  }

  .emptyBanner {
    @include fill-parent-width;
    @include card;
    overflow: hidden;

    padding: 10px 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .empty {
      font-family: $font-family;
      font-weight: bold;
      display: flex;
      justify-content: flex-end;

      font-size: 1.175rem;
      margin-top: 3rem;
    }
  }
}
