@import 'helpers';
@import 'theme';

.tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 5px 8px;
  font-size: 10px;
  font-weight: 500;
  color: var(--color);
  border-radius: 10px;
  height: fit-content;
  white-space: nowrap;
  width: fit-content;
  font-family: $font-family;

  .whiteThemeText {
    color: #333333;
  }

  .text::before {
    content: '';
    height: var(--size);
    width: var(--size);
    background-color: var(--color);
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
}
