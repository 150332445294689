@import 'helpers';

.subMenuHorizontal {
  $border-color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);

  display: flex;
  flex-shrink: 0;
  position: relative;
  flex-direction: column;
  @include fill-parent-width;
  justify-self: flex-start;
  padding-right: 5px;
  box-sizing: border-box;
  margin-right: 30px;
  max-height: 200px;

  .wrapper {
    display: flex;

    ul {
      display: flex;
      flex: 1;
      flex-shrink: 0;
      flex-direction: row;
      list-style-type: none;
      padding: 0 0 5px 0;
      margin: 0;
      column-gap: 8px;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
      }
    }

    .margin {
      width: 144px;
      flex-shrink: 1;
    }
  }
}
