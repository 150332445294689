@import 'theme';

.radioGroup {
  display: flex;
  flex-direction: column;

  > label {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 600;
    padding: 11px 0;
    color: $system-text-gray;
    text-transform: uppercase;

    span {
      &.required {
        color: $obligated-red;
      }

      &.optional {
        color: $system-dark-gray;
      }
    }
  }

  .table {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;

    td {
      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
