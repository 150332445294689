@import 'helpers';
@import 'theme';

.floatMessage {
  display: inline-flex;
  flex-shrink: 0;
  position: absolute;
  right: 0;

  background-size: 15% 46%;
  background-repeat: no-repeat;
  background-position: center;

  > div {
    @include flex-center;
    width: $input-height;
    height: $input-height;
    font-size: $input-height * 0.7;
    cursor: pointer;
  }

  @mixin color($fg, $bg) {
    color: $fg;
    @include hover-growing-shadow(150%, $fg);
    background-image: linear-gradient(to right, $bg 0%, $bg 100%);
  }

  &.error {
    @include color($error-color, $text-color-error);
  }

  &.warning {
    @include color($warning-color, $text-color-warning);
  }

  &.info {
    @include color($info-color, $text-color-info);
  }
}

.popup {
  font-family: $font-family !important;
  font-size: $font-size !important;
  line-height: $font-size/10px !important;

  &[data-reference-hidden] {
    opacity: 0 !important;
  }

  @mixin color($bg, $fg) {
    color: $fg !important;
    background-color: $bg !important;

    :global(.tippy-arrow) {
      color: $bg !important;
    }
  }

  &.error {
    @include color($error-color, $text-color-error);
  }

  &.warning {
    @include color($warning-color, $text-color-warning);
  }

  &.info {
    @include color($info-color, $text-color-info);
  }
}
