@import 'helpers';
@import 'theme';

.autocompleteInput {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  outline: none;
  position: relative;

  &.active {
    .icon {
      color: $primary-color;
    }
  }

  @extend .autocompleteInput;

  height: $input-height;

  .input {
    @include input-box;
    @include ellipsis;

    line-height: $input-height * 0.8;
    user-select: none;
    text-transform: uppercase;

    &::placeholder {
      display: inline-block;
      white-space: nowrap;
      opacity: 0.5;

      text-transform: initial;

      font-family: $font-family;
      font-size: $font-size;
      font-weight: 300;
      color: $regular-color;
    }
  }

  .adornment {
    @include flex-center;

    flex: 0 0 auto;
    color: $regular-color;
    background-color: transparent;
    font-family: $font-family;
    font-size: $font-size * 0.92;
    font-weight: 300;
    padding: 8px;
    user-select: none;

    @at-root .disabled#{&} {
      color: $disabled-color;
    }

    @at-root .readonly#{&} {
      color: $text-color-primary;
    }
  }

  .controls {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;

    div {
      display: flex;
      color: mix($primary-color, #fff, 60%);
      padding: 8px;
      transition: color 150ms ease 0s;
      box-sizing: border-box;
      font-size: $font-size * 1.4;
      cursor: pointer;

      &:hover {
        color: darken($primary-color, 40%);
      }
    }
  }
}

$border-width: 2px;

:export {
  borderWidth: $border-width;
}

.popup {
  $border-radius: 10px;

  cursor: pointer;
  outline: none;
  border-radius: $border-radius;
  background-color: #fff;
  overflow: hidden;

  border: 0.5px solid $system-inter-gray;
  box-shadow: 0 0.2rem 0.5rem rgba(69, 77, 93, 0.3);

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 240px;
    overflow-y: auto;

    background-color: rgba($primary-color, 0.15);

    li {
      display: flex;
      position: relative;
      min-height: $input-height;
      padding: 4px 4px 4px 8px;
      box-sizing: border-box;

      font-family: $font-family;
      font-size: $font-size;
      font-weight: 300;
      line-height: $input-height * 0.8;

      color: $regular-color;
      background-color: #fff;
      user-select: none;
      outline: none;

      &:first-child {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba($primary-color, 0.1);
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:not(.selected):hover::after {
        background-color: rgba($primary-color, 0.15);
      }

      &.selected {
        color: $text-color-primary;
        background-color: $primary-color;
      }
    }

    @include pretty-scroll-bar;
  }
}
