.table {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;

  td {
    > div {
      display: flex;
      flex-direction: column;
    }
  }
}
