@import 'helpers';
@import 'theme';

.grid {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(4, 1fr);
}

.accountDetail {
  display: flex;
  flex-direction: column;
  row-gap: 45px;
  padding-bottom: 32px;

  .header {
    display: flex;
    gap: 7px;
    align-items: stretch;
    flex-direction: row;
    .configButton {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .totalCard {
      background: #ffffff;
      padding: 0;
      border-radius: 12px;
      font-family: $font-family;
      padding: 16px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      border: 1px solid #eeeeee;
      width: fit-content;

      div {
        margin-right: 24px;
        span {
          text-transform: uppercase;
          color: #5d5a71;
          font-size: 12px;
          font-weight: 400;
        }
        p {
          font-weight: 600;
          color: #25213b;
          font-size: 18px;
        }
      }
    }
    svg {
      color: #37a64a;
    }
  }
}

.insideHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: $system-black;
    line-height: 24px;
  }
}
