@import 'theme';

.identification {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .radioGroup {
    padding: 20px 25px;
  }

  .simulationCheck {
    margin: 20px 0px;
    padding: 4px 16px 4px 8px;

    position: relative;
    right: 1%;
    border-radius: 9px;
    border: 1px dashed #868686;
    box-sizing: border-box;
  }
}
