@import 'helpers';
@import 'theme';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.grid {
  width: 100%;
  padding-block: 16px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, 340px);
}
