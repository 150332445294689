@import 'helpers';

.reset {
  @include flex-center;
  flex-direction: column;

  height: 340px;

  & > div {
    width: 100%;
  }

  .title {
    font-family: $font-family;
    font-size: 21px;
    font-weight: 600;
    color: $regular-color;
    text-align: center;
    text-transform: uppercase;
  }

  .subtitle {
    @extend .title;
    font-size: 12px;
    font-weight: 500;
    text-transform: inherit;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }
}
