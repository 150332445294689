@import 'helpers';

$duration: 0.2s;

.check {
  $boxSize: 24px;

  display: inline-flex;
  align-items: center;

  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: background-color $duration, color;
  padding: 5px 0;

  .box {
    display: flex;

    > div {
      @include flex-center;
      position: relative;
      flex-shrink: 0;

      width: $boxSize;
      height: $boxSize;
      border-radius: 100px;
    }

    .icon {
      @include flex-center;
      position: absolute;

      font-size: $boxSize;
      color: $input-bg;
      border-radius: 3px;
      opacity: 1;

      transition: all 0.5s ease;

      &:not(:first-child) {
        transform: scale(0);
        transition: transform $duration ease-in;
      }
    }
  }

  .label {
    font-size: $font-size;
    font-family: $font-family;
    font-weight: 400;

    color: $system-text-gray;

    transition: color $duration;
    margin-left: 10px;
  }

  @mixin color($color) {
    .box {
      .icon {
        &:first-child {
          color: $input-bg;
        }
        &:last-child {
          color: $general-dark-green;
        }
      }
    }

    .label {
      color: $color;
    }
  }
  &:not(.selected) {
    .box {
      .icon {
        &:hover {
          &:first-child {
            color: $general-dark-green;
            opacity: 0.25;
          }
        }
      }
    }
  }
  &.selected {
    .box {
      .icon {
        &:first-child {
          color: $input-bg;
        }
        &:last-child {
          color: $general-dark-green;
          transform: scale(1);
        }
      }
    }

    &.filled {
      .box {
        @include hover-growing-shadow($color: $text-color-primary);
      }
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    @include color(lighten($regular-color, 32.94));
  }

  &.readonly {
    pointer-events: none;
    cursor: default;
    @include color(lighten($regular-color, 32.94));
  }
}
