@import 'helpers';

.textarea {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  outline: none;

  .input {
    @include pretty-scroll-bar;
    @include input-box;

    line-height: 1.4;
    padding-top: ($input-height - ($font-size * 1.4))/2;
    box-sizing: border-box;
    min-height: $input-height;
    resize: none;
  }

  &::placeholder {
    display: inline-block;
    white-space: nowrap;
    opacity: 0.5;

    text-transform: initial;

    font-family: $font-family;
    font-size: $font-size * 0.8;
    font-weight: 300;
    color: lighten($regular-color, 32.94);
  }

  @include round-input-content;
}
