@import 'theme';
@import 'helpers';

.financial {
  td {
    padding: 0 5px;
  }

  td:nth-child(1) {
    width: 20%;
  }

  tbody > tr:nth-child(odd) {
    background-color: $system-light-gray;
  }

  .partItem {
    position: relative;
    top: 20%;
  }

  .labelKeys {
    font-weight: 600;
    color: $system-black;
  }
}
