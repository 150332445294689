@import '../Check/Check.module.scss';

.tsCheck {
  $color: lighten(saturate(adjust-hue($primary-color, 1), 5), 13.73);
  $color-alt: #6fa8ff;

  @extend .check;
  @include flex-center;

  transition: background-color $duration;
  border-radius: 0;
  background-color: #c4c4c4;
  .box {
    padding: 5px 0;
    > div {
      @include flex-center;
      background-color: #ffffff;
      width: 16px;
      height: 16px;
      border-radius: 0;
    }
    .icon {
      color: $color !important;
      font-size: 18px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

      &:first-child {
        transition: color $duration ease-in;
      }

      &:not(:first-child) {
        background-size: 60% 60%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: linear-gradient(to right, #fff 0%, #fff 100%);
      }

      &:nth-child(3) {
        color: $color-alt !important;
      }
    }
  }

  &[data-state='1'] {
    background-color: $general-dark-green;

    .box {
      @include hover-growing-shadow($color: $text-color-primary);

      .icon {
        &:nth-child(2) {
          transform: scale(1.1);
        }
      }
    }
  }

  &[data-state='2'] {
    background-color: darken(desaturate($color-alt, 47.06), 21.76);

    .box {
      @include hover-growing-shadow($color: $text-color-primary);

      .icon {
        &:first-child {
          transition: color $duration ease-in $duration;
          color: $color-alt !important;
        }

        &:nth-child(3) {
          transition: transform $duration ease-in $duration;
          transform: scale(1);
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }
}
