@import 'helpers';
@import 'theme';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.grid {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
}

.tag {
  padding: 2px 10px;
  border-radius: 39px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  max-width: 45px;

  &.credit {
    background-color: #3fc75c82;
    color: #2e7d32;
  }

  &.debit {
    background-color: #e1e1e1;
    color: #333333;
  }
}

.order {
  @include flex-center;
  font-size: $font-size;
  background-color: #e1e1e1;
  padding: 2px 8px;
  color: $system-black;
  font-weight: $font-weight-medium;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  .label {
    margin-left: 5px;
  }
}

.expenseCard {
  border-radius: 12px;
  min-width: 300px;
  font-family: $font-family;

  .title {
    font-size: 18px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 13px;
  }

  .footer {
    margin-top: 10px;
    @include flex-center;
    justify-content: space-between;
    width: 100%;
  }
}

.expensePart {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 18px 16px;
  font-family: $font-family;
  border: 1px solid #eeeeee;
  row-gap: 10px;

  .title {
    font-size: 18px;
    color: #333333;
    font-weight: 600;
    margin: 0;
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 8px;
  }

  .footer {
    @include flex-center;
    justify-content: space-between;
    width: 100%;
  }
}

.modalContent {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 20px;

  .modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 {
      font-family: $font-family;
      font-size: 18px;
    }

    span {
      cursor: pointer;
    }
  }
}
