@import 'theme';
@import 'helpers';

.box {
  display: inline-flex;
  align-items: center;
  background: #f6f9fa;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  width: 12px;
  height: 12px;
  border: 2px solid #44cf5c;
  border-radius: 3px;
  &.active {
    color: #f6f9fa;
    background: #44cf5c;
  }
}
