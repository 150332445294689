@import 'helpers';

.subMenu {
  $border-color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);

  display: flex;
  flex-shrink: 0;
  position: relative;
  flex-direction: column;
  @include fill-parent-height;

  padding-right: 5px;
  box-sizing: border-box;
  margin-right: 30px;
  max-width: 320px;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;

    background-color: $border-color;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }

  > div {
    display: flex;
    flex-direction: column;
    position: relative;
    @include fill-parent-width;

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;

      background-color: $border-color;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    }

    > span {
      font-family: $font-family;
      font-weight: 700;
      font-size: $font-size * 2.14;

      text-transform: uppercase;

      padding: 0 31px 20px 13px;
      box-sizing: border-box;
      user-select: none;
    }

    .wrapper {
      display: flex;

      ul {
        display: flex;
        flex: 1;
        flex-shrink: 0;
        flex-direction: column;
        list-style-type: none;
        padding: 0 0 22px 0;
        margin: 0;

        a {
          text-decoration: none;
        }

        li {
          position: relative;
          display: flex;
          align-items: center;
          width: 200px;
          height: 41px;
          border-radius: 0px 20px 20px 0px;
          margin: 4.8px 0px;
          padding-left: 13px;
          box-sizing: border-box;

          font-family: $font-family;
          font-weight: 700;
          font-size: $font-size;

          background-color: $section-bg-color;
          color: #aaa;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

          &.selected {
            background-color: lighten(
              desaturate(adjust-hue($primary-color, 1), 3.92),
              3.14
            );

            color: $text-color-primary;
          }

          &:hover {
            background-color: darken(
              desaturate(adjust-hue($primary-color, 1), 13.64),
              9.22
            );
            color: $text-color-primary;
          }

          .adornment {
            position: absolute;
            right: 0;
          }
        }
      }

      .margin {
        width: 144px;
        flex-shrink: 1;
      }
    }
  }
}
