@import 'helpers';

.configPanel {
  display: flex;
  @include fill-parent-all;
  overflow: hidden;
  flex-direction: column;
  gap: 0.75rem;

  .topMenu {
    display: flex;
    flex-direction: row;
  }
  .content {
    display: flex;
    flex: 1;
    @include fill-parent-height;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1300px) {
  .configPanel {
    gap: 2rem;
  }
}
