@import 'helpers';

.topbar {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 88px !important;
  max-height: 88px !important;
  padding: 12px 40px 12px 0;
  box-sizing: border-box;
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    column-gap: 12px;
    .logo {
      @include flex-center;
      width: 244px;
    }
  }

  .userContainer {
    display: flex;
    align-items: center;
    user-select: none;

    a {
      @include flex-center;
      position: relative;
      width: 38px;
      height: 38px;
      font-size: 23px;
      margin: 0 8px;
      text-decoration: none;
    }

    svg {
      color: $system-black;

      &:hover {
        color: $regular-color;
      }
    }

    .notification {
      span {
        position: absolute;
        background-color: #007aff;
        height: 8px;
        width: 8px;
        border-radius: 4px;
        top: 0;
        right: 0;
      }
    }

    > div {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-left: 30px;
      }

      cursor: pointer;
    }

    .user {
      display: flex;
      flex-direction: column;
      margin: 0 16px;

      span {
        font-family: $font-family;
        text-transform: capitalize;
        color: $system-black;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;

        &:nth-child(2) {
          color: #9c9c9c;
          font-size: 10px;
          line-height: 10px;
          font-weight: 400;
        }
      }
    }

    .icon {
      @include flex-center;

      margin-top: 3px;
      margin-left: 8px;

      font-size: $font-size * 1.5;
      transform: rotate(180deg);

      transition: transform 0.2s;
      color: lighten(desaturate(adjust-hue($regular-color, 1), 3.92), 3.14);
      cursor: pointer;

      &:not(.open) {
        transform: rotate(360deg);
      }
    }
  }
}

.popup {
  height: auto;
  width: 175px;
  cursor: pointer;

  background: #f7f8fc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;

    li {
      display: flex;
      position: relative;
      min-height: 30px;
      align-items: center;
      padding: 4px 4px 4px 8px;
      box-sizing: border-box;

      font-family: $font-family;
      font-weight: 400;
      font-size: $font-size * 1.143;
      line-height: $input-height * 0.4706;

      list-style-type: none;
      border-bottom: 1px solid $system-light-gray;

      a {
        display: flex;
        height: auto;
        width: 175px;
        justify-content: left;
        align-items: center;
        text-decoration: none;
      }

      span {
        color: lighten(desaturate(adjust-hue($regular-color, 1), 3.92), 3.14);
      }

      &:only-child {
        border-bottom: none;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: darken(desaturate(adjust-hue($regular-color, 1), 13.64), 0.22);

        span {
          color: #ffffff;
        }

        &:first-child {
          border-radius: 6px 6px 0px 0px;
        }

        &:last-child {
          border-radius: 0px 0px 6px 6px;
        }

        &:not(:last-child):not(:first-child) {
          border-radius: 0px;
        }
      }
    }
  }
}
