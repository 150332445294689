@import 'helpers';
@import 'theme';

.container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  font-family: $font-family;

  strong {
    color: #25213b;
    margin-left: 0.5rem;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
}

.progressModalActions {
  display: flex;
  align-items: center;
  justify-content: center;
}
