@import 'helpers';
@import 'theme';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.paymentPanel {
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  color: $text-color-light-gray;

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    padding: 0 23px;

    > div {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .paymentPanel {
    zoom: 0.9;
  }
}

.executedModal {
  padding: 1rem;
  font-family: $font-family;

  .buttonConfirm {
    display: flex;
    justify-content: center;
  }
}
