@import 'theme';

.changeProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  height: 100%;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 150px;
    height: 150px;
    margin: 10px 20px 40px 20px;
  }

  .badge {
    border-radius: 100%;
    background-color: $system-light-gray;
    color: $system-medium-gray;
    cursor: pointer;
    flex-shrink: 0;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 50px;
    }

    span {
      font-family: $font-family;
      font-size: $font-size * 1.28;
      font-weight: 400;
    }

    &.selected {
      background-color: $primary-color;
      color: $system-white;
    }

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}
