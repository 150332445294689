@import 'helpers';

.stepper {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 20px 0 25px 0;

  background-color: $section-bg-color;

  $progress-color: mix($primary-color, #fff, 50%);

  .step {
    display: flex;
    align-items: center;
    flex-direction: column;

    .number {
      @include flex-center;
      position: relative;
      width: 100%;

      span {
        @include flex-center;

        font-family: $font-family;
        font-size: $font-size * 2;
        font-weight: 300;

        width: $font-size * 2.8;
        height: $font-size * 2.5;

        color: $section-fg-color;
        border-radius: 4px;
        background-color: $section-bg-color;

        z-index: 1;
      }

      &::before {
        content: '';
        position: absolute;
        width: $font-size * 2.8;
        height: $font-size * 2.5;
        border-radius: 4px;
        box-shadow: 0px 0px 0px 5px $progress-color;
        background: $progress-color;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        border: 1px solid $progress-color;
        border-width: 1px 0 1px 0;
        background: $progress-color;
      }
    }

    .title {
      @include flex-center;
      font-family: $font-family;
      font-size: $font-size * 0.8;
      font-weight: 300;
      color: $section-fg-color;
      padding: 10px;
      text-transform: uppercase;
    }

    &.done {
      .number {
        span {
          background: $progress-color;
          color: $section-fg-color;
        }
      }
    }

    &.selected {
      .number {
        span {
          background: $primary-color;
          color: $text-color-primary;
          font-weight: 500;
        }
      }

      .title {
        font-weight: 500;
      }
    }

    &.disabled {
      .number {
        span {
          color: $progress-color;
        }

        &::before {
          background: $section-bg-color;
          box-shadow: 0px 0px 0px 1px $progress-color;
        }

        &::after {
          background: $section-bg-color;
        }
      }

      .title {
        color: $progress-color;
      }
    }
  }
}
