@import 'helpers';

.link {
  display: flex;

  font-family: $font-family;
  font-weight: 400;
  font-size: $font-size * 1.15;
  line-height: 19px;

  color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
