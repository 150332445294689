@import 'helpers.scss';
@import 'consts.scss';

.milestone {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 5px;

  .header {
    display: flex;
    flex-shrink: 0;
    position: sticky;
    left: 0;
    pointer-events: auto;
    user-select: none;

    background-color: #eceff1;
    border-radius: 100px;
    margin-right: auto;
    margin-bottom: 5px;

    .arrow {
      @include flex-center;
      color: #607d8b;
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      font-size: 15px;
      transition: transform 0.3s;
      visibility: hidden;

      &.visible {
        transform: rotate(-180deg);
      }
    }

    span {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      font-family: $font-family;
      font-size: $font-size * 0.857;
      font-weight: 400;
      color: #263238;
      padding: 0 26px;
    }

    &.interactive {
      cursor: pointer;

      .arrow {
        visibility: visible;
      }
    }
  }

  .body {
    display: flex;

    .container {
      display: flex;
      flex-direction: column;
      pointer-events: auto;
      transition: width $anim_duration $anim_func, margin-left $anim_duration $anim_func;
    }
  }

  &.active {
    .header {
      pointer-events: none;
    }

    .body {
      .container {
        pointer-events: none;
      }
    }
  }

  &:not(:first-child) {
    margin-top: 5px;
  }

  &:last-child {
    margin-bottom: 20px;
  }
}
