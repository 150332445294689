@import 'helpers';

.tableSortLabel {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: center;
  align-self: center;

  span {
    text-wrap: nowrap;
  }

  &:hover {
    text-decoration: underline;
  }

  .arrow {
    $size: 12px;
    @include flex-center;
    align-self: center;
    position: absolute;
    top: 3px;
    right: -($size + 2);

    transform: rotate(180deg);

    font-size: 10px;
    width: $size;
    height: $size;
    transition: transform 0.3s;
    color: $system-white;
    background-color: #6e6e6e;
    border-radius: 50%;

    &.desc {
      transform: rotate(0deg);
    }
  }
}
