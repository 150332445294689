@import 'helpers';
@import 'theme';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.button {
  $radius: 25px;

  display: inline-flex;
  flex-shrink: 0;
  min-width: 98px;
  height: 32px;

  border-radius: $radius;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  outline: none;

  box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.25s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  margin: 5px;

  > div {
    display: flex;
    flex-grow: 1;
    position: relative;
    height: 100%;
    border-radius: $radius;

    padding: 8px 30px;
    box-sizing: border-box;

    outline: none;
    text-decoration: none;

    transition-property: background-color, box-shadow, border;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .text {
    @include flex-center;
    flex: 1;

    font-family: $font-family;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.331915px;
  }

  .icon {
    @include flex-center;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    font-size: $font-size * 1.43;
  }

  @mixin hover-shadow($color) {
    box-shadow: 0px 2px 4px -1px rgba($color, 0.2), 0px 4px 5px 0px rgba($color, 0.14),
      0px 1px 10px 0px rgba($color, 0.12);
  }

  @mixin filled($fg, $bg, $hover: $bg, $value: 10%) {
    color: $fg;
    background-color: $bg;

    &:hover {
      @include hover-shadow(#000);
      background-color: darken($hover, $value);
      color: #fff !important;

      .text[data-hoverable='true'] {
        color: #fff !important;
      }
    }

    .text {
      font-weight: 700;
    }
  }

  @mixin slim($color) {
    color: $color;
    background-color: transparent;
    box-shadow: none;

    &:hover {
      @include hover-shadow(#000);
    }

    &.icon {
      border: 1px solid rgba($color, 0.4);
    }

    .text {
      font-weight: 400;
    }
  }

  &.loading {
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.active {
    color: #fff !important;
    background-color: darken(
      desaturate(adjust-hue($primary-color, 1), 13.64),
      9.22
    ) !important;
  }

  &.primary:not(.minimalist) {
    @include filled(
      $system-white,
      lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14)
    );
  }

  &.secondary {
    @include slim($text-color-primary-variant-dark);
  }

  &.danger {
    @include filled($text-color-danger, $danger-color);
  }

  &.disabled {
    @include slim($disabled-color);

    border-radius: 9px;
    height: 38px;

    pointer-events: none;
    cursor: default;

    .icon {
      background: transparent;
    }
  }
  &.icon {
    &.left {
      .text {
        padding-left: 36px;
      }

      .icon {
        left: 12px;
      }
    }

    &.right {
      .text {
        padding-right: 36px;
      }

      .icon {
        right: 12px;
      }
    }
  }

  &[data-active] {
    box-shadow: 0px 5px 5px -3px rgba(#000, 0.2), 0px 8px 10px 1px rgba(#000, 0.14),
      0px 3px 14px 2px rgba(#000, 0.12) !important;
  }

  &.light:not(.disabled) {
    height: 40px;
    border-radius: 9px;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);

    @include filled(
      $system-black,
      #fff,
      darken(desaturate(adjust-hue($primary-color, 1), 13.64), 9.22),
      0
    );

    > div {
      padding: 8px 47px;
    }

    &:hover {
      color: darken(saturate(adjust-hue($text-color-primary, 228), 45.45), 2.16);
    }

    .text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      letter-spacing: -0.011em;
    }

    &.icon {
      &.left {
        .text {
          padding-left: 19px;
        }
      }

      &.right {
        .text {
          padding-right: 19px;
        }
      }
    }
  }

  &.minimalist:not(.disabled) {
    height: fit-content;
    border-radius: 12px;
    box-shadow: none;

    color: #5d5a71;
    background-color: #ffffff;

    > div {
      padding: 12px 18px;
    }

    &:hover {
      opacity: 0.8;
    }

    .text {
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
    }
    .icon {
      color: #808597;
    }

    &.icon {
      &.left {
        .text {
          padding-left: 24px !important;
        }
      }

      &.right {
        .text {
          padding-right: 24px !important;
        }
      }
    }
  }
}
