@import 'theme';
@import 'helpers';

.projectDetails {
  font-family: $font-family;

  .readOnlyTable {
    box-shadow: 0;
  }

  .closeIcon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .fontes {
    padding: 29px 25px;

    p {
      font-weight: bold;
      margin-bottom: 0.25rem;
    }
  }
}

@media (min-width: 1600px) and (max-width: 1800px) {
  .projectDetails {
    zoom: 0.8;
  }
}

@media (min-width: 1301px) and (max-width: 1599px) {
  .projectDetails {
    zoom: 0.7;
  }
}

@media screen and (max-width: 1300px) {
  .projectDetails {
    zoom: 0.6;
  }
}
