@import 'theme';
@import 'helpers';

.loader {
  @include flex-center;
  flex: 1;
  color: $primary-color;
  margin: auto;

  overflow: hidden;

  > div {
    @include flex-center;
    flex-direction: column;

    span {
      display: none;
      font-size: $font-size;
      font-family: $font-family;
      font-weight: 500;
      padding: $font-size * 2;
    }

    &.delay {
      animation: fade 0.6s;
    }
  }

  &.error {
    color: $error-color;
  }

  &.message {
    padding-top: $font-size * 2;
    span {
      display: flex;
    }
  }

  @keyframes fade {
    0%,
    80% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
