@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $font-family;
  row-gap: 1.5rem;
  width: fit-content;
  position: relative;

  .title {
    color: $text-color-light-gray;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  .pieChart {
    @include flex-center;
    svg:not(.empty) {
      animation: rotate 1.2s ease-in-out;

      path {
        opacity: 1;
        transition: 0.5s;
        animation: scale 0.9s ease-in-out;
        transform-origin: center center;

        &:hover,
        &.selected {
          opacity: 0.6;
          transform: scale(1.1);

          transform-origin: center center;
        }
      }
    }
    svg.empty {
      animation: fade-in 0.5s ease-in-out;
    }
  }
  .legend {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    column-gap: 4rem;
    margin-left: 10px;
    & > * {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
