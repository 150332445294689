@import 'helpers';
@import 'theme';

@include tippy-popup;

.container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 150px;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: 52%;
    width: 100%;

    .description {
      @include flex-center;
      flex-direction: row;
      justify-content: space-between;
      min-height: 65px;

      width: 100%;
    }
    .footer {
      margin-top: 20px;
      @include flex-center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 5px;
      .order {
        @include flex-center;
        font-size: $font-size;
        background-color: #fff;
        padding: 4px 10px;
        color: #37a64a;
        font-weight: $font-weight-medium;
        border-radius: 22px;
        cursor: pointer;
        user-select: none;
        transition: all 0.3s ease-in-out;
        border: 1px solid #37a64a;

        &:hover {
          opacity: 0.8;
        }

        .label {
          margin-left: 5px;
        }
      }
    }
  }
}

.deleted {
  opacity: 0.5;
}

.statusTag {
  position: absolute;
  top: 0;
  right: 0;
}
