@import 'helpers';

.content {
  display: flex;
  flex-direction: column;
  @include fill-parent-all;

  .viewer {
    display: flex;
    flex-grow: 1;
    @include fill-parent-width;
  }

  .footer {
    margin-top: 20px;
  }
}
