.grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.row {
  display: flex;
  width: 100%;
  align-items: flex-end;

  > div {
    box-sizing: border-box;
    flex-shrink: 0;
  }

  &.center {
    align-items: center;
  }

  &.top {
    align-items: flex-start;
  }

  &.stretch {
    align-items: stretch;
  }
  &.bottom {
    align-items: flex-end;
  }

  &.baseline {
    align-items: baseline;
  }
}
