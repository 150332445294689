@import 'helpers';
@import 'theme';

.estimativeCard {
  flex: 1 1 100px;
  background: white;
  padding: 8px 16px;
  border-radius: 12px;
  font-family: $font-family;

  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #eeeeee;
  width: fit-content;

  .title {
    color: #5d5a71;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;

    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 15px;

    .contentItem {
      display: flex;
      flex-direction: column;
      gap: 5px;
      color: #5d5a71;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.34px;
      letter-spacing: 0.05em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-transform: uppercase;

      span {
        font-weight: 700;
        font-size: 14px;

        &.positive {
          color: #118f00;
        }
        &.negative {
          color: #ee0004;
        }
      }
    }
  }
}
