@import 'helpers';
@import 'theme';

.textInput {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  height: $input-height;
  outline: none;
  position: relative;

  .icon {
    @include flex-center;
    flex-shrink: 0;

    font-size: 14px;
    color: #686868;
    margin-left: 1em;
  }

  &.active {
    .icon {
      color: #000000;
    }
  }

  .input {
    @include input-box;
    @at-root .disabled#{&} {
      color: $disabled-color;
      font-weight: 300;
    }

    @at-root .readonly#{&} {
      color: darken($text-color-primary, 33.33);
    }
  }

  .adornment {
    @include flex-center;
    flex: 0 0 auto;
    color: black;
    background-color: transparent;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 400;
    line-height: $font-size * 1.17;
    padding: 8px;
    user-select: none;

    @at-root .disabled#{&} {
      color: $disabled-color;
    }

    @at-root .readonly#{&} {
      color: $disabled-color;
    }
  }

  @include round-input-content;
}
