@import 'theme';
@import 'helpers';

.dashboard {
  .headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    margin-bottom: 30px;
  }
  .sectionTitle {
    font-size: 16px;
    font-weight: 600;
    color: $text-color-light-gray;
  }
}

.selectManagerModal {
  padding: 20px;
}
