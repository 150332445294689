.buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  .customControls {
    display: flex;
    flex: 1;
  }

  .buttonsForm {
    display: flex;
  }
}

.dialogButtons {
  @extend .buttons;
  margin-bottom: 20px;
}
