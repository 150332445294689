@import 'helpers';

.peopleView {
  display: flex;
  height: 100%;

  flex-direction: column;

  font-family: $font-family;
  color: $text-color-primary-variant-dark;

  .peopleViewHeader {
    .actions {
      padding: 20px 0px;
    }

    div > h2 {
      margin-bottom: 1.25rem;
    }
  }
}
