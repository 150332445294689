@import 'helpers.scss';

.joint {
  display: flex;
  position: relative;
  width: 29px;
  flex-shrink: 0;
  align-self: stretch;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    stroke: darken(desaturate(adjust-hue($primary-color, 1), 13.64), 9.22);
    stroke-width: 1px;

    line {
      &.dashed {
        stroke: $system-medium-gray;
        stroke-width: 1px;
        stroke-dashoffset: 0;
        stroke-dasharray: 2;
        stroke-linecap: square;
        stroke-linejoin: round;
      }
    }
  }
}
