@import 'helpers';
@import 'theme';

.conclusionPanel {
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  color: $text-color-light-gray;

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    padding: 0px 23px;
  }
}
