@import 'theme';

.content {
  font-family: $font-family;

  .partItem {
    position: relative;
    top: 20%;
  }

  tbody {
    .labelKeys {
      font-weight: 600;
      color: $system-black;
      width: 15%;
    }

    .value {
      width: 4%;
    }

    .percentage {
      width: 2%;
    }
    td {
      text-align: center;
    }
  }
}
