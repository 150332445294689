@import 'theme';

.errorDialog {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  span {
    font-family: $font-family;
    font-size: $font-size * 1.2;
    font-weight: 600;
    color: $section-fg-color;
  }

  ul {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 500;
    color: $section-fg-color;
    padding: 0;
    margin: 20px;

    li {
      margin: 5px 0;
    }
  }
}
