@import 'helpers.scss';

.tableList {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: $font-family;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    font-family: $font-family;

    .titles {
      h2 {
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: -0.387234px;
        opacity: 0.5;
        margin: 0;
        margin-bottom: 0px;
      }
      h1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: -0.387234px;
        margin: 0;
      }
    }
  }

  table {
    position: relative;
    background-color: $table-bg-color;
    table-layout: fixed;
    width: 100%;

    border-collapse: separate;
    border-spacing: 0;
    box-sizing: border-box;

    thead {
      th {
        position: sticky;

        background-color: #fff;

        text-align: center;
        font-family: $font-family;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        color: $system-black;
        box-sizing: border-box;

        padding: 9px 16px;

        user-select: none;
      }

      th[scope='row'] {
        left: 0;
        z-index: 1;
      }

      th:not([scope='row']) {
        top: 0;
        z-index: 2;
      }
    }

    tbody {
      td {
        text-align: center;
        font-family: $font-family;
        font-size: 18px;
        line-height: 21px;
        font-weight: 400;
        padding: 10px 16px;
      }
    }
  }

  &.light {
    animation: fadeIn 0.2s ease-in-out;
    .filtersManager {
      @include flex-center;
      justify-content: flex-end;
      align-items: center;
      margin: 5px;

      .iconButton {
        @include flex-center;
        margin-left: 20px;
        .buttonLabel {
          padding: 5px;
          font-size: 12px;
        }
      }
    }
    .addButton {
      @include flex-center;
      cursor: pointer;
      flex-direction: column;
      background-color: #fff;
      border-radius: 15px;

      padding: 10px;

      box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
      svg {
        size: 50px;
        color: black;
      }

      span {
        font-family: $font-family;
        font-size: 11px;
        font-weight: 500;
        color: #312f3a;
      }
      &:hover {
        background-color: $general-light-green;
        box-shadow: 0px 30px 27px rgba(0, 0, 0, 0.05);
        transition: background-color 0.25s linear;
      }
    }
    table {
      tr:not(.ignoreDualColor) {
        &:nth-child(odd) {
          td {
            background-color: $input-bg;
          }
        }
      }

      td,
      th {
        background-color: #fff;
        font-size: 12px;
        color: #5d5a71;
        line-height: 16px;
      }

      thead {
        th {
          div {
            font-size: 12px;
          }

          text-transform: uppercase;
          font-weight: 600;
          color: #5d5a71;
          border-bottom: 0.5px solid $table-border-color;
        }
      }
      tbody {
        tr {
          transition: background-color 0.2s;
          color: $system-dark-gray;

          &:not(:first-child) {
            td {
              border-top: 0.5px solid $table-border-color;
            }
          }
        }
        td {
          text-align: center;
          padding: 10px 20px;
          word-break: break-all;

          strong {
            font-size: 14px;
            line-height: 19px;
            font-weight: 600;
            color: #25213b;
          }
        }
      }
    }
  }

  &.small {
    table {
      thead {
        th {
          font-size: 16px;
        }
      }
    }
  }
}

.grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, 340px);
}

@media screen and (max-width: 1367px) {
  .tableList {
    .header {
      padding: 0;
    }
  }

  .adminTable {
    flex-direction: row-reverse;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 1600px) {
  .tableList {
    zoom: 0.9;
  }
}

:export {
  barWidth: $active-scroll-bar-width;
  bgColor: $table-bg-color;
  bgColorLight: #fff;
}
