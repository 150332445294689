@import 'helpers';
@import 'theme';

.container {
  padding: 25px 0;

  table {
    width: 100%;
    color: $system-black;
    border-spacing: 5px;

    thead {
      tr {
        th {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 13px;
          padding: 12px 2px;
        }
        th:first-child {
          text-align: left;
        }

        th:nth-child(4) {
          text-align: right;
        }
      }
    }

    tbody {
      // Get element with colspan 2

      td[rowSpan='2']:first-child {
        background-color: $input-bg;
        border-radius: 6px;
      }

      td[rowSpan='2']:nth-child(2) {
        background-color: $text-color-primary;
        border-radius: 5px;
        border: 1px solid #ece9f1;

        div[role='button'] {
          border: none;
        }
      }

      tr {
        text-align: center;

        td {
          width: 20%;
        }

        td:first-child {
          text-align: left;
        }

        td:nth-child(4) {
          text-align: right;
        }
      }
    }
  }
}
