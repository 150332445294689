@import 'helpers';

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  min-width: 640px;
  height: 100%;
  padding: 20px;
  min-height: 0;
  box-sizing: border-box;

  animation: fadeIn 0.2s ease-in;

  .header {
    font-family: $font-family;

    text-transform: uppercase;
    text-align: center;

    div {
      &:first-child {
        font-size: 12px;
        font-weight: 500;
        color: $regular-color;
      }

      &:last-child {
        font-size: 27px;
        font-weight: 600;
        color: $primary-color;
        letter-spacing: 0.8px;
      }
    }
  }

  .content {
    @include flex-center;
    padding: 15px 0;
    min-height: 0;
    box-sizing: border-box;
    margin: 20px 0;
    overflow: auto;
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .footerButtons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      span {
        font-size: 0.875rem;
        font-weight: 400;
        font-family: $font-family;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
