@import 'theme.scss';

.notificationMessage {
  border-radius: 5px;
  min-width: 376px;
  display: flex;
  transition: all 2s ease-in-out;
  margin-right: 20px;
  padding: 8px;
  align-items: center;

  &.hide {
    animation: slide-out 0.35s cubic-bezier(0.21, 1.02, 0.73, 1) forwards;
  }

  &:not(.hide) {
    animation: slide-in 0.4s forwards cubic-bezier(0.06, 0.71, 0.55, 1);
  }

  .errorIcon {
    color: $system-white;
    display: flex;
    font-size: 14px;
  }

  .warnIcon {
    color: $warning-color-medium;
    display: flex;
    font-size: 28px;
  }

  .successIcon {
    display: flex;
    font-size: 14px;
    color: white;
  }

  .content {
    font-family: 'Roboto';
    font-size: 12px;
    padding: 5px;
    white-space: initial;
    text-align: justify;
  }

  &.hide {
    animation: slide-out 0.8s ease;
  }
}

.dangerColor {
  background-color: $danger-color;
  color: $system-white;
}

.warningColor {
  background-color: $warning-color;
  color: $regular-color;
}

.successColor {
  background-color: $success-color;
  color: $system-white;
}

@keyframes slide-in {
  from {
    transform: translate3d(0, var(--in-translate), -1px) scale(0.6);
    opacity: 0.5;
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translate3d(0, 0, -1px) scale(1);
    opacity: 1;
  }
  to {
    transform: translate3d(0, var(--in-translate), -1px) scale(0.6);
    opacity: 0;
  }
}
