@import 'helpers';

.contacts {
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    &:first-child {
      height: 320px;
    }
  }

  .content {
    margin: 15px 0;
  }

  .add {
    display: flex;
    justify-content: flex-end;

    span {
      font-family: $font-family;
      font-size: $font-size;
      font-weight: 700;

      color: $primary-color;
      text-transform: uppercase;
      margin-top: 10px;

      cursor: pointer;

      &:hover {
        color: darken($primary-color, 20%);
      }
    }
  }

  .remove {
    @include flex-center;

    color: $danger-color;
    font-size: 16px;
    margin: 3px;
  }
}
