@import 'helpers.scss';

.schedule {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  box-sizing: border-box;

  .containers {
    display: flex;
    flex-direction: column;
    pointer-events: none;
    min-height: 380px;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    .completion {
      position: sticky;
      left: 0;
      right: 0;
      bottom: 20px;
      margin-bottom: 20px;
    }
  }

  .invalid {
    @include flex-center;
    width: 100%;
    height: 100%;
    padding: 15%;
    box-sizing: border-box;
  }

  &[data-op='resize_e'] {
    cursor: e-resize;
  }

  &[data-op='resize_w'] {
    cursor: w-resize;
  }

  &[data-op='move_marker'] {
    cursor: col-resize;
  }

  &[data-op='move_box'] {
    cursor: move;
  }
}
