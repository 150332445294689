@import 'theme';

.counter {
  font-family: $font-family;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  color: #09101d;
  opacity: 0.6;
  margin-left: 10px;
}
