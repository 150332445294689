@import 'theme';

.toggle {
  display: inline-flex;
  padding: 2px 8px;
  border-radius: 20px;
  background-color: $system-medium-gray;
  color: #fff;
  &.active {
    background-color: $general-dark-green;
  }
}
