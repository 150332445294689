@import 'theme';

.loginForm {
  display: flex;
  flex-direction: column;
  height: 340px;
  margin-top: 1rem;

  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .cardItem {
      width: 85%;
      border-radius: 16px;
    }

    &:hover {
      cursor: pointer;
    }

    p {
      color: #171717;
      font-weight: 600;
    }
  }

  .cards > div {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08), 0px 20px 27px rgba(0, 0, 0, 0.05);
  }

  .link {
    margin-top: 1.25rem;
    font-family: $font-family;
    font-size: 0.875rem;
    font-weight: 400;
    color: $regular-color;
    text-align: center;

    &:first-child {
      opacity: 0.8;
    }

    &.forgot {
      font-family: $font-family;
      font-size: 12px;
      line-height: 16px;

      text-align: left;

      padding: 0;
      margin: 12px 9px 0 0;
    }

    a {
      text-decoration: none;
      outline: none;

      &:focus {
        text-decoration: underline;

        span {
          color: darken($primary-color, 20%);
        }
      }
    }

    span {
      &:last-child {
        font-weight: 700;
        color: #00ad43;
        margin-left: 5px;

        &:hover {
          color: darken($primary-color, 20%);
          text-decoration: underline;
        }
      }
    }
  }
}
