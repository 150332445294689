@import 'helpers';
@import 'theme';

.conclusionPanel {
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  color: $text-color-light-gray;

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 24px;
    padding: 0 20px;
    > div:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 16px;
    }
  }
}

.popup {
  padding: 20px;
  p {
    font-family: $font-family;
  }
}

@include modal-popup;
