@import 'helpers';

.item {
  display: flex;
  align-items: center;
  min-height: 45px;
  border-radius: 8px;
  cursor: pointer;

  font-family: $font-family;
  font-weight: normal;
  font-size: $font-size;
  color: rgba(0, 0, 0, 0.87);
  column-gap: 10px;

  transition: all 0.3s ease;

  .icon {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #78909c;
  }

  .label {
    min-height: 20px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.44px;
    overflow: hidden;
    text-overflow: ellipsis;

    text-wrap: nowrap;

    color: rgba(0, 0, 0, 0.87);
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 768px) {
    .item {
      min-width: 45px;
      min-height: 45px;
      .label {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .label {
      font-size: 12px;
    }
  }

  .adornment {
    position: absolute;
    top: 0;
  }

  &.selected {
    background-color: $general-light-green;

    div {
      color: $general-dark-green;
    }
  }

  .container {
    display: flex;
    padding: 0 10px;
    min-height: 45px;
    border-radius: 8px;
    column-gap: 10px;
    width: 100%;

    &.hasChanges {
      background: rgba(255, 245, 0, 0.19);
      border: 1px solid #fff500;
    }
  }

  &.blocked:not(.selected) {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover:not(.blocked):not(.selected) {
    background-color: lighten(saturate(adjust-hue($primary-color, 1), 5), 50.73);

    div {
      color: $general-dark-green;
    }
  }
}
