@import 'helpers';

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 15px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f8f9fa;
  border-radius: 8px;
  overflow: hidden;

  @include fill-parent-height;

  .header {
    display: flex;
    justify-content: space-between;
    @include fill-parent-width;
    min-height: 80px;
    background: #f8f9fa;
    padding: 10px 28px;
    box-sizing: border-box;
    align-items: center;

    .desc {
      display: flex;
      flex-direction: column;

      span {
        font-family: $font-family;
        font-size: 14px;
        font-weight: 400;
        color: #686868;
        line-height: 19.07px;

        &:last-child {
          font-size: 18px;
          font-weight: 600;
          color: $system-black;
          line-height: 24.51px;
        }
      }
    }

    .controls {
      display: flex;
      flex-shrink: 0;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @include fill-parent-width;
    box-sizing: border-box;
    padding: 8px;
  }

  .banner {
    @include flex-center;
    flex-direction: column;
    margin: auto;
    user-select: none;

    img {
      width: 285px;
      height: 277px;
    }

    span {
      font-family: $font-family;
      font-size: 18px;
      line-height: 25px;
      font-weight: 700;
      color: $system-black;
      width: 322px;
      text-align: center;
      margin-top: 40px;
    }
  }
}
