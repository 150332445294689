@import 'theme';

.companyList {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;

  .adornmentError {
    color: $error-color;
    position: relative;
    margin-top: -9px;
  }

  .adornmentWarn {
    color: $warning-color-medium;
    position: relative;
    margin-top: -9px;
  }

  .section {
    display: flex;

    .company {
      display: flex;
      flex-direction: column;

      .name {
        display: flex;
        flex-direction: column;

        :first-child {
          margin-bottom: 10px;
          font-family: $font-family;
          font-weight: 500;
          font-size: $font-size;
          line-height: $font-size * 1.43;
          color: $text-color-light-gray;
        }

        :last-child {
          display: inline-flex;
          align-items: center;
          font-family: $font-family;
          font-weight: 600;
          font-size: $font-size * 1.2;
          color: $text-color-primary-variant-dark;
        }
      }

      .managerTitle {
        font-family: $font-family;
        font-weight: 600;
        text-transform: uppercase;
        font-size: $font-size;
        color: $system-text-gray;
        margin: 10px 0;
      }

      .manager {
        padding: 17px 20px;
        border-radius: 9px;
        border: 1px dashed #868686;
        box-sizing: border-box;
      }
    }
  }
}
