@import 'theme';
@import 'helpers';

.projectList {
  display: flex;
  gap: 17px;
  grid-row-gap: 0px;
  flex-wrap: wrap;
  align-items: center;
}

.statusTag {
  position: absolute;
  top: 0;
  right: 0;
}
