@import 'helpers';
@import 'theme';

.counterpart {
  display: flex;
  flex-direction: column;
  width: 738px;

  .total {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
  }
}
