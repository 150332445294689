@import 'helpers';

.data {
  @include flex-center;
  position: relative;
  flex-direction: column;
  @include fill-parent-all;

  .wrapper {
    display: flex;
    flex-direction: column;
    @include fill-parent-all;

    animation: fadeIn 0.2s ease-in-out forwards;
  }

  .loader {
    position: absolute;
  }

  &.loading {
    .wrapper {
      animation: fadeOut 0.2s ease-in-out forwards;
    }
  }
}
