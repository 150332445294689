@import 'theme';
@import 'helpers';

@include tippy-popup;

.budget {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 37px;
  }

  > span {
    font-family: $font-family;
    font-weight: 500;
    font-size: 16px;
    color: $text-color-light-gray;
    text-transform: uppercase;
    margin-bottom: 0px;
    padding: 0px 20px;
    user-select: none;
  }

  table {
    font-family: $font-family;
    color: $system-black;
    background-color: $table-bg-color;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 965px;
    width: min-content;
    color: $system-text-gray;

    thead {
      th {
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        padding: 2px 20px;
        box-sizing: border-box;
        user-select: none;
        text-transform: uppercase;
        min-width: 140px;

        &:first-child {
          text-align: left;
          width: 2%;
        }

        &:nth-child(2) {
          width: 2%;
        }
      }

      td {
        font-weight: 500;
        text-align: center;
        padding: 7.75px 20px;
        box-sizing: border-box;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          td {
            background-color: $input-bg;
          }
        }
      }
      td {
        color: $system-text-gray;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        padding: 10px 5px;
        box-sizing: border-box;

        border-top: 1px solid $system-light-gray;
        border-bottom: 1px solid $system-light-gray;
        background-color: transparent;

        &:first-child {
          overflow: auto;
          text-overflow: auto;
          min-width: 300px;
          padding: 1px 20px;
          text-align: left;
        }

        &:nth-child(2) {
          padding: 1px 12px;
          text-align: ce;
        }
      }
    }
  }
}
