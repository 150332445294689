@import 'helpers.scss';

.edit {
  display: flex;
  flex: 1;
  min-height: 0;

  flex-direction: column;

  &.dialog {
    margin: 35px 40px;
  }

  &:not(.dialog) {
    width: fit-content;
  }

  .status {
    @include flex-center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
  }
}
