@import 'theme';
@import 'helpers';

.summary {
  display: flex;
  overflow: auto;
  flex-direction: column;
  height: 100%;

  cursor: pointer;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap-reverse;
    width: 100%;
    margin-bottom: 1rem;

    .totals {
      & {
        width: 550px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 32px;

        @media screen and (max-width: 600px) {
          flex-direction: column;
          width: 500px;
          row-gap: 32px;
        }
      }
    }
    .addButton {
      position: absolute;
      right: 0;
      margin: 0.3125rem 2.5rem 1rem 1rem;

      @media screen and (max-width: 880px) {
        position: relative;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    .filters {
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1600px) {
  .summary {
    zoom: 0.9;
  }
}
