@import 'theme';
@import 'helpers';

.counterpartView {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 2rem;

  font-family: $font-family;
  color: $text-color-primary-variant-dark;

  .header {
    @include flex-center;
    flex-direction: row;
    justify-content: flex-start;
  }

  .actions {
    @include flex-center;
    width: 100%;
    justify-content: flex-start;
    .file,
    .manage {
      @include flex-center;
      flex-direction: row;
    }
  }

  .scrollContent {
    display: flex;
    width: 100%;
    @include fill-parent-all;
  }

  .backButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .viewButtons {
    margin-bottom: 20px;
  }

  .formButtons {
    @include flex-center;
    padding: 20px 0;
  }
}

.popup {
  padding: 20px;

  .modalTitle {
    font-family: $font-family;
  }
}
