@import 'helpers';

.zstack {
  display: flex;
  flex-direction: column;
  @include fill-parent-all;

  align-items: center;
  justify-content: center;

  $duration: 0.1s;

  > div {
    display: flex;
    flex-direction: column;
    @include fill-parent-all;

    height: 0;
    opacity: 0;
    overflow: hidden;
    display: none;

    transition: height 0s linear $duration, opacity $duration linear;

    animation: fadeIn 0.6s ease-in-out;

    &.selected {
      height: 100%;
      opacity: 1;
      display: inherit;
      transition: height 0s linear $duration, opacity $duration linear $duration;
    }
  }
}
