@import 'helpers';

.wrapperCardResponsive {
  @include default-card;

  animation: fadeIn 0.3s ease;
  display: flex;
  transition: height 0.3s ease, width 0.3s ease, minWidth 0.3s ease;
  overflow-x: hidden;

  > div {
    width: 100%;
    height: 100%;
    margin-bottom: auto;
  }
}

.wrapperCard {
  @include default-card;
  width: auto;

  animation: fadeIn 0.3s ease-in-out;
}
