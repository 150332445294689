@import 'helpers';
@import 'theme';

.documents {
  font-family: $font-family;
  color: $system-text-gray;
  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .documentsTitle {
    font-weight: 600;
    text-transform: uppercase;
  }

  .uploadedDocuments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.25rem;

    &:hover {
      cursor: pointer;
    }
  }
}
