@import 'helpers';
@import 'theme';

.counterpart {
  display: flex;
  flex-direction: column;
  width: 738px;

  .total {
    display: flex;
    margin-bottom: 20px;
    column-gap: 10px;
  }
}
