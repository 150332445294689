@import 'helpers';

.fileList {
  display: flex;
  flex-direction: column;

  .label {
    font-family: $font-family;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #5d5a71;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 6px;
  }

  .link {
    display: inline-flex;
    font-family: $font-family;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $system-black;
    padding: 3px 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
