@import 'helpers';
@import 'theme';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.importLimit {
  font-family: $font-family;
  color: #5d5a71;
  font-size: 12px;
  font-weight: 500;
}

.importList {
  font-family: $font-family;

  display: flex;
  flex-direction: column;

  max-height: 500px;

  .listTitle {
    margin: 30px 0;
    color: #252f40;
    font-size: 18px;
    font-weight: 600;

    .listEnum {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .uploadedItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .icon {
      background: #d5ecd9;
      border-radius: 50%;
      padding: 4px;
      gap: 10px;

      display: flex;
      align-items: center;

      color: #e51616;

      &.success {
        color: #2f9e41;
      }

      svg {
        width: 25px;
        height: 25px;
      }
    }

    .error {
      background: #ffbcbc;
    }

    span {
      font-family: $font-family;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: black;

      display: flex;
      flex: 1;
      margin-left: 12px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  flex-wrap: wrap;

  .dataContainer {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    .cards {
      display: inline-flex;
      column-gap: 19px;

      .card {
        display: inline-flex;
        color: #252f40;
        font-weight: 700;

        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        background-color: white;
        width: fit-content;

        .cardTitle {
          font-family: $font-family;
          font-size: 16px;
          font-weight: 600;
          color: #4f4f4f;
          line-height: 1.3;
          margin-bottom: 20px;
        }

        .cardText {
          font-family: $font-family;
          display: flex;
          justify-content: space-between;
          align-items: baseline;

          .cardTextMain {
            font-size: 30px;
            font-weight: 700;
          }

          .cardTextSecondary {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }

  .alerts {
    display: inline-flex;
    column-gap: 19px;
    width: 100%;

    .alertContainer {
      border-radius: 12px;
      padding: 20px;
      background-color: white;
      display: inline-flex;
      flex-direction: column;
      min-width: 470px;

      .alertContainerTitle {
        font-family: $font-family;
        font-size: 16px;
        font-weight: 700;
        border-bottom: 2px solid black;
        padding: 8px 9.5px;
        margin-bottom: 10px;
        width: fit-content;
      }

      .alertContainerList {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        max-height: 400px;
        padding-right: 16px;
      }
    }
  }
}

.dialogText {
  font-family: $font-family;
}
