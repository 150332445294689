@import 'helpers';

.card {
  margin-top: 1rem;

  h2 {
    @include default-header;
  }
}

.container {
  width: 100%;
  height: 100%;

  .buttonContainer {
    display: flex;
    flex: 2;
    flex-direction: row;
    justify-items: center;
    justify-content: center;
  }
}

.container {
  font-family: $font-family;
  font-weight: 600;

  h1 {
    padding: 8px 0;
    font-size: 16px;
    color: $text-color-primary-variant-dark;
  }
  h2 {
    font-size: 12px;
    color: $system-text-gray;
  }
}
