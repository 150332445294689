@import 'helpers';

$border: 1px;

.scrollbar {
  $width: 7px + $border * 2;
  $duration: 200ms;
  $delay: 10ms;

  position: absolute;
  user-select: none;
  opacity: 0;
  z-index: 10;
  overflow: visible;

  .track {
    display: flex;
    position: absolute;
    background-color: rgba(196, 196, 196, 0.46);

    border-radius: $width / 2;
    transition-duration: $duration;
    transition-delay: $delay;
    padding: $border;
    box-sizing: border-box;

    .thumb {
      border-radius: $width / 2;
      background-color: #3e3e3e;
      transition-duration: $duration;
      transition-delay: $delay;

      &:active {
        background-color: #3e3e3e;
      }
    }
  }

  &.horizontal {
    height: $active-scroll-bar-width * 0.8;

    .track {
      transition-property: opacity, height;
      height: $width;
      left: $border;
      right: $border;

      .thumb {
        transition-property: height;
        height: 100%;
      }
    }

    &.start {
      top: 0;

      .track {
        top: $border;
      }
    }

    &.end {
      bottom: 0;

      .track {
        bottom: $border;
      }
    }
  }

  &.vertical {
    width: $active-scroll-bar-width * 0.8;

    .track {
      transition-property: opacity, width;
      width: $width;
      top: $border;
      bottom: $border;

      .thumb {
        transition-property: width;
        width: 100%;
      }
    }

    &.start {
      left: 0;

      .track {
        left: $border;
      }
    }

    &.end {
      right: 0;

      .track {
        right: $border;
      }
    }
  }

  &:hover,
  &:active {
    &.horizontal {
      .track {
        height: $active-scroll-bar-width;
      }
    }

    &.vertical {
      .track {
        width: $active-scroll-bar-width;
      }
    }
  }

  :hover > &,
  :active > & {
    transition: opacity none;
    animation: fadeIn 0.1s;
    opacity: 1;
  }

  @keyframes fadeIn {
    0%,
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

:export {
  barWidth: $active-scroll-bar-width;
  border: $border;
}
