.collapse {
  display: flex;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-in-out;

  > div {
    width: 100%;
    margin-bottom: auto;
  }
}
