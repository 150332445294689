@import 'helpers';

.reset {
  display: flex;
  flex-direction: column;

  .title {
    text-align: center;
    padding-bottom: 50px;
    flex: 1;
    font-size: $font-size * 1.57;
    line-height: $font-size * 1.57;
    font-family: $font-family;
    color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);
    font-weight: 600;
  }
}
