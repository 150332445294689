@import 'helpers';

.configPanel {
  display: flex;
  @include fill-parent-all;
  overflow: hidden;

  .content {
    display: flex;
    flex: 1;
    @include fill-parent-height;
    box-sizing: border-box;
    padding: 0 23px;
  }
}
