@import 'helpers';

.budget {
  display: flex;
  flex-direction: column;
  @include fill-parent-all;
  max-width: fit-content;

  > div {
    display: flex;
    flex-direction: column;

    &:last-child {
      flex: 1;
    }
  }
}
