@import 'helpers';

$boxSize: 16px;
$padding: 9px;

.content {
  width: 95%;
  padding: 15px 0;
  height: auto;

  &.edit {
    padding: 20px 0;
  }

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #aaaaaa;

    .number {
      padding: 3px 8px;
      border-radius: 10px;
      background-color: lighten(saturate(adjust-hue($primary-color, 1), 5), 13.73);
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: $font-size * 1.57;
      line-height: $font-size * 1.85;
      color: $text-color-primary;
    }

    .question {
      margin: 0px 15px;
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: $font-size * 1.28;
      line-height: $font-size * 1.5;
      color: darken($regular-color, 13.73);
    }
  }

  .text {
    height: 120px;
    margin-top: 20px;
    background: $section-bg-color;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px 6px 0px 0px;
    border-bottom: 2px solid lighten(saturate(adjust-hue($primary-color, 1), 5), 13.73);
  }

  .functions {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    padding-top: 20px;
    border-top: 1px solid #aaaaaa;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        cursor: pointer;
        margin: 10px;
      }
    }
  }

  .status {
    @include flex-center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.options {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 10px 0;

    span {
      margin: 0 15px;
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: $font-size * 1.14;
      line-height: $font-size * 1.36;
      color: darken($regular-color, 13.73);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & .itemEdit {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .option {
      display: flex;
      width: 70%;
      align-items: center;
    }

    .iconDelete {
      top: $padding;
      left: $padding;
      width: $boxSize;
      height: $boxSize;

      display: flex;
      justify-content: flex-end;

      cursor: pointer;
      font-size: $boxSize;
      color: lighten(desaturate($error-color, 5.08), 1.96);
      border-radius: 3px;
      opacity: 1;
    }
  }

  .newOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 16px;

    span {
      cursor: pointer;
      margin: 0 15px;
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: $font-size;
      line-height: $font-size * 1.17;
      color: lighten($regular-color, 32.94);
    }
  }
}

.icon {
  top: $padding;
  left: $padding;
  width: $boxSize;
  height: $boxSize;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: $boxSize;
  color: lighten(saturate(adjust-hue($primary-color, 1), 5), 13.73);
  border-radius: 3px;
  opacity: 1;
}
