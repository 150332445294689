@import 'theme';

.title {
  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.553191px;
  color: $text-color-primary-variant-dark;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.tabs {
  max-width: 800px;
}
