@import 'theme';
@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.actionButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  > :not(:first-child) {
    margin-left: 10px;
  }
}

@include modal-popup;

.popup {
  margin: 0px !important;
}
