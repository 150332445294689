@import 'helpers';
@import 'theme';

.totalCard {
  font-family: $font-family;
  padding-right: 5rem;
  h1 {
    color: $text-color-light-gray;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 7px;
    white-space: nowrap;
  }

  p {
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
  }
}
