@import 'helpers';

.circularProgress {
  @include flex-center;

  svg {
    circle {
      stroke-width: 31.37px;
      stroke-dashoffset: 0;
      opacity: 0.2;
      fill: transparent;
      transition: stroke-dashoffset 1s linear;

      &.bar {
        opacity: 1;
      }
    }
  }
}
