@import 'helpers';

.stepper {
  @include flex-center;
  user-select: none;

  background-image: linear-gradient(
    to bottom,
    lighten(desaturate(adjust-hue($primary-color, 3), 5.81), 12.75),
    darken(saturate($primary-color, 4.89), 7.65)
  );

  > div {
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    padding: 35px 10px;
    box-sizing: border-box;

    p {
      font-family: $font-family;

      color: #fff;
      text-align: center;

      margin: 0px;

      &:first-of-type {
        font-size: 23px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-top: 66px;
      }

      &:nth-of-type(2) {
        font-size: 11px;
        font-weight: 500;
        width: 200px;
        letter-spacing: 0.5px;
      }
    }
  }

  .body {
    @include flex-center;
    flex: 1;
    padding-bottom: 35px;

    .steps {
      display: flex;
      position: relative;
      justify-content: center;
      flex-direction: column;
      flex: 1;

      width: 100%;
      padding-left: 30px;
      box-sizing: border-box;

      .slider {
        position: absolute;
        left: 16px;
        right: 0;
        top: 0;
        height: 60px;
        background-color: lighten(desaturate(#2f9e41, 13.66), 19.61);
        opacity: 0.3;

        transition: top 0.2s ease-in-out;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
      }

      .step {
        display: flex;
        position: relative;
        align-items: center;
        width: 100%;
        margin: 12px 0;
        z-index: 1;

        $bg: lighten(saturate(adjust-hue($primary-color, 2), 20.05), 47.65);

        div {
          @include flex-center;
          font-family: $font-family;
          font-size: 15px;
          font-weight: 600;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          color: darken($primary-color, 10%);
          background-color: $bg;
        }

        span {
          flex: 1 1;
          padding-left: 13px;
          font-family: $font-family;
          font-size: 14px;
          font-weight: 700;
          color: #fff;
          letter-spacing: 0.8px;
        }

        i {
          display: flex;
          position: absolute;
          height: 100%;
          color: $bg;

          top: 0;
          left: -18px;
        }
      }
    }
  }
}
