@import 'helpers';
@import 'theme';

.cancelConfirm {
  font-family: $font-family;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.51px;
  letter-spacing: -0.5531914830207825px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin-bottom: 20px;
}

.cancelModalItem {
  font-family: $font-family;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  letter-spacing: 0.05em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #5d5a71;

  display: flex;
  flex-direction: column;
  span {
    color: #333333;
    font-weight: 400;
  }
}
