@import 'helpers';
@import 'theme';

.conclusionNode {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  overflow: hidden;
  cursor: not-allowed;

  .checkbox {
    color: $system-black;
    display: flex;
    align-self: center;
    margin: auto;
    background-color: white;
    width: fit-content;

    box-shadow: inset 0 0 0 2px currentColor, 2px 2px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.selected {
      opacity: 1 !important;
    }
  }

  &.clickable {
    cursor: pointer;
  }
  &.clickable:hover {
    .checkbox {
      opacity: 1;
    }
  }
}
