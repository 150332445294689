@import 'theme';

.panel {
  width: 800px;
  background: #ffffff;
  border-radius: 4px;
  //border: 1px solid $section-fg-color;
  padding: 0 18px;
  margin: auto;
  box-sizing: border-box;
  text-decoration: none;
  flex: 0 0 auto;

  .title {
    display: block;
    font-family: $font-family;
    font-weight: 500;
    font-size: $font-size * 1.3;
    margin: 0 0 14px 0;
    padding: 14px 0;
    border-bottom: 1px solid $divider-color;
    text-align: center;
    color: $section-fg-color;
    text-transform: uppercase;
  }

  .footer {
    margin: 14px 0 0 0;
    border-top: 1px solid $divider-color;
    padding: 10px 0;
    flex: 0 0 auto;
  }
}
