@import 'helpers';

.inputControl {
  display: flex;
  flex-direction: column;
  position: relative;

  background-color: $input-bg;
  transition: all 0.4s;
  outline: none;
  border-radius: 6px;

  & > div {
    display: flex;
    align-items: center;
  }

  .badgeContainer {
    display: flex;
    align-self: flex-start;
    animation: fadeIn 0.5s linear;
  }

  .badge {
    @include flex-center;

    flex: 0 0 auto;
    width: $input-height;
    height: $input-height;
    font-size: 22px;

    &.rotate {
      color: $primary-color;
      animation: rotation 2s linear infinite;
    }
  }

  .inputWrapper {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;

    &:first-child {
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
    }

    &:last-child {
      border-top-right-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
  }

  @mixin border($color, $include-bg: true, $outline: true) {
    @if ($outline) {
      @include input-border($color);
    }

    @if ($include-bg) {
      background-color: mix($color, #fff, 5%);
    }

    &:hover {
      @include input-border($color, 0.8px);
    }

    &.active {
      @include input-border($color, 1px);
    }
  }

  &.sneaky {
    border-radius: 6px;
  }
  &.classic,
  &.skinny {
    border-radius: 6px;
    background-color: $input-bg;
    margin-bottom: 12px;

    .errorMessage {
      position: absolute;
      font-family: $font-family;
      font-size: 10px;
      font-weight: 500;

      color: $error-color;

      top: 105%;
    }

    &.plain,
    &.success {
      @include border($regular-color, false, false);
    }

    &.error {
      @include border($error-color);
    }

    &.warning {
      @include border($warning-color);
    }

    &.disabled {
      @include input-border($disabled-color, 0.5px);
    }
  }
  &.compact-inverted {
    background-color: #ffffff;

    &.plain,
    &.success {
      @include border(#b3b3b3, false, true);
    }

    &.error {
      @include border($error-color);
    }

    &.warning {
      @include border($warning-color);
    }

    &.disabled {
      @include input-border($disabled-color, 0.5px);
    }
  }

  &.compact {
    &.plain,
    &.success {
      @include border($regular-color, false, false);
    }

    &.error {
      @include border($error-color);
    }

    &.warning {
      @include border($warning-color);
    }

    &.disabled {
      @include input-border($disabled-color, 0.5px);
    }
  }

  &.transparent {
    &.plain,
    &.success {
      @include border($regular-color, false, false);
    }

    &.error {
      @include border($error-color);
    }

    &.warning {
      @include border($warning-color);
    }

    &.disabled {
      @include input-border($disabled-color, 0.5px);
    }
    background-color: transparent;
    input {
      color: $system-text-gray;
      font-weight: 600;
    }
  }

  &.button {
    background-color: transparent;
  }

  &.disabled {
    cursor: default;
    user-select: none;
    pointer-events: none;
  }

  &.readonly {
    @extend .disabled;

    .badge {
      color: darken($text-color-primary, 33.33);
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1turn);
    }
  }
}
