@import 'helpers';
@import 'theme';

.button {
  @include flex-center;
  cursor: pointer;
  color: $background-color;
  background-color: $general-dark-green;
  padding: 7px 14px;
  border-radius: 22px;
  transition: all 0.3s ease-in-out;

  font-size: 13px;
  font-family: $font-family;
  font-weight: 500;

  &.disabled {
    background-color: $system-light-gray;
    cursor: not-allowed;
    color: $system-dark-gray;
  }

  &:hover:not(.disabled) {
    opacity: 0.8;
  }
  span {
    @include flex-center;
    margin-left: 10px;
  }
}
