@import 'helpers';
@import 'theme';

$box-size: 25px;
$box-margin: 3px;

:export {
  boxSize: $box-size;
  boxMargin: $box-margin;
}

@include tippy-popup;

.monthYearPicker {
  flex: 1;
  width: 100%;
  .anchor {
    font-family: 'Poppins', serif;
    display: flex;
    flex-direction: row;

    cursor: pointer;
    user-select: none;
    outline: none;

    border: 1px solid #ece9f1;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;

    &.disabled,
    &.readonly {
      opacity: 0.5;
    }

    .label {
      font-size: 14px;
      font-weight: 400;
      color: #11263c;
      width: 100%;
      margin: 0 5px;
    }
    .icon {
      margin-left: 5px;
      color: $icon-color;
    }
  }
}

.picker {
  font-family: $font-family !important;
  font-size: 16px;
  padding: 10px;
  box-shadow: 0 0.2rem 0.5rem rgba(69, 77, 93, 0.3);
  border-radius: 10px;
  background-color: #fff;

  .yearSelector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .year {
      font-size: 18px;
      font-weight: 500;
    }

    .arrow {
      @include flex-center;
      color: $primary-color;
      cursor: pointer;
      padding: 5px;
      border-radius: 15px;

      &:hover {
        background-color: $general-light-green;
      }
    }
  }

  .months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .month {
      @include flex-center;

      flex-shrink: 0.6;
      width: $box-size;
      height: $box-size;
      border-radius: 10px;
      margin: $box-margin;
      padding: 10px;
      color: $system-black;
      cursor: pointer;

      transition: all 0.2s ease-in-out;

      &.selected {
        background-color: $primary-color;
        color: $text-color-primary;
      }
      &:hover {
        background-color: $general-light-green;
      }
    }
  }
}
