@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.plainField {
  display: flex;
  flex-direction: column;
  padding: 10px;

  &.none {
    padding: 0;
  }

  span {
    font-family: $font-family;

    &.label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #5d5a71;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      margin-bottom: 6px;
    }

    &.value {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $system-black;
      @include break-word;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}

.overlay {
  border-radius: $input-border-radius * 2;

  background: rgba(255, 245, 0, 0.19);
  border: 1px solid #fff500;
}
