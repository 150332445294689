@import 'theme';
@import 'helpers';

@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.fileUploader {
  display: flex;
  flex-direction: column;
  padding: 5px 0;

  .label {
    font-family: $font-family;
    font-style: normal;
    font-size: $font-size;
    font-weight: 600;
    line-height: $font-size * 1.14;
    text-transform: uppercase;

    color: $system-text-gray;

    @at-root .disabled#{&} {
      color: $disabled-color;
    }

    span {
      &.required {
        color: $obligated-red;
      }

      &.optional {
        color: $system-dark-gray;
      }
    }
  }

  .header {
    display: flex;

    .icon {
      @include flex-center;

      font-size: 45px;
      color: $primary-color;
    }

    .info {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      justify-content: center;

      span {
        color: $section-fg-color;
        font-family: $font-family;
        font-weight: 300;
        font-size: $font-size * 0.82;
      }

      > div {
        display: flex;
        align-items: center;

        span {
          font-size: $font-size * 1.3;
          margin-right: 5px;
        }
      }

      input {
        display: none;
      }
    }
  }

  .fileInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    color: $system-text-gray;
    font-family: $font-family;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    &.sneaky {
      padding: 10px;
      column-gap: 5px;

      &:has(.uploadedItem) {
        padding: 3px;
      }
    }

    &.button {
      column-gap: 5px;
    }

    p {
      margin: 0;
      opacity: 0.5;
    }

    input {
      display: none;
    }

    .icon {
      color: $icon-color;
      font-size: 18px;
    }

    .checkIcon {
      background: #d5ecd9;
      border-radius: 50%;
      padding: 4px;
      gap: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .transition {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .fileList {
    display: flex;
    padding: 15px 0;

    &.classic > div {
      padding: 20px;
    }
    &.sneaky > div {
      padding: 5px;
    }

    > div {
      display: flex;
      flex: 1 1 auto;
      max-width: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      box-sizing: border-box;

      color: $system-text-gray;
      border: 1px dashed #868686;
    }

    &.highlight {
      > div {
        background-color: mix($primary-color, #fff, 20%);
      }
    }

    .uploadedItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .icon {
        background: #d5ecd9;
        border-radius: 50%;
        padding: 4px;
        gap: 10px;

        svg {
          width: 25px;
          height: 25px;
        }
      }

      span {
        font-family: $font-family;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: black;

        display: flex;
        flex: 1;
        margin-left: 12px;
      }
    }
  }

  .buttonFileList {
    display: flex;

    > div {
      display: flex;
      flex: 1 1 auto;
      max-width: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      box-sizing: border-box;

      color: $system-text-gray;
    }

    &.highlight {
      > div {
        background-color: mix($primary-color, #fff, 20%);
      }
    }
  }

  .buttonLabel {
    color: #252f40;
    opacity: 1 !important;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.331915px;
    margin-left: 10px;
  }

  .uploadedFiles {
    color: #fff;
  }

  .uploaded {
    color: #fff !important;
  }
}

.buttonFile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .buttonToolsArea {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .iconAlign:hover {
      cursor: pointer;
    }

    .iconAlign {
      @include flex-center;
    }

    p {
      display: inline;
    }
  }
}
