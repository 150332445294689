@import 'helpers';

.selectInput {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  outline: none;
  position: relative;

  .button {
    @extend .selectInput;

    height: $input-height;
    cursor: pointer;

    .input {
      @include input-box;
      @include ellipsis;
      display: flex;

      user-select: none;

      &.placeholder {
        overflow: hidden;
        white-space: normal;

        &::before {
          content: '';
        }

        div {
          display: inline-block;
          white-space: nowrap;
          opacity: 0.5;

          font-family: $font-family;
          font-size: $font-size;
          font-weight: 300;
          color: $regular-color;
        }
      }
    }

    .icon {
      @include flex-center;
      color: $regular-color;
      flex-shrink: 0;
      padding-right: 15px;
      padding-left: 5px;
      font-size: 10px;
    }
  }

  &.disabled {
    .input {
      color: $disabled-color;
      font-weight: 300;
    }
  }

  &.readonly {
    .input {
      color: darken($text-color-primary, 33.33);
    }
  }
}

$border-width: 2px;

:export {
  borderWidth: $border-width;
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: $input-height;

  font-family: $font-family;
  font-size: $font-size;
  font-weight: 400;
  line-height: $input-height * 0.48;
  color: $regular-color;

  padding: 4px 4px 4px 8px;
  box-sizing: border-box;

  &.selected {
    color: $select-text-hover-color;
    background-color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);
  }

  &:not(.selected) {
    color: lighten(desaturate(adjust-hue($primary-color, 1), 3.92), 3.14);
  }
}
