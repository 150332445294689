@import 'theme';
@import 'helpers';

.rolesList {
  display: flex;
  flex-direction: column;
  font-family: $font-family;
  color: $text-color-light-gray;

  font-size: 14px;
  font-weight: 600;

  .header {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    font-size: 20px;
    font-weight: 600;
    color: $text-color-primary-variant-dark;
  }

  .grid {
    display: grid;
    grid-column-gap: 12px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }
}
