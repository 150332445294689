@import 'helpers';
@include tippy-popup($color: $text-color-light-gray, $background: #ffff);

.multiSelect {
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;

  min-height: $input-height;
  outline: none;
  box-sizing: border-box;

  > div:first-child {
    @extend .multiSelect;
    user-select: none;
    max-width: 100%;
  }

  .boxContainer {
    display: flex;
    position: relative;
    align-items: center;
    flex: 1 1 0%;
    flex-wrap: wrap;

    padding: 4px 0 4px 8px;
    overflow: hidden;
    box-sizing: border-box;

    > div {
      $border-radius: 2px;

      display: flex;
      border-radius: $border-radius;
      margin: 2px;
      min-width: 0px;
      box-sizing: border-box;

      box-shadow: 0px 0px 0px 1px $primary-color;

      span {
        display: inline-block;
        font-family: $font-family;
        font-size: $font-size * 0.97;
        font-weight: 400;
        color: darken($primary-color, 40%);

        border-radius: $border-radius;
        overflow: hidden;
        padding: 3px 3px 3px 6px;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }

      i {
        display: flex;
        align-items: center;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        font-size: $font-size;
        color: $primary-color;

        padding: 0 4px;
        box-sizing: border-box;
        background-color: mix($primary-color, #fff, 15%);

        cursor: pointer;

        &:hover {
          color: darken($primary-color, 40%);
          background-color: mix($primary-color, #fff, 40%);
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .boxContainer {
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      > div {
        flex: 1;
        span {
          font-size: 6px;
          padding: 0px 0px 0px 2px;
          word-break: break-all;
        }
      }
    }
  }

  .icon {
    @include flex-center;
    color: $regular-color;
    flex-shrink: 0;
    padding: 0 8px;
    font-size: 10px;
  }
  .controls {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;

    div {
      display: flex;
      color: mix($primary-color, #fff, 60%);
      padding: 6.2px;
      transition: color 150ms ease 0s;
      box-sizing: border-box;
      font-size: $font-size * 1.4;
      cursor: pointer;

      &:hover {
        color: darken($primary-color, 40%);
      }
    }

    span {
      align-self: stretch;
      background-color: $regular-color;
      margin-bottom: 8px;
      margin-top: 8px;
      width: 0.5px;
      box-sizing: border-box;
    }
  }
  &.button {
    flex-grow: 0;

    > div {
      .boxContainer {
        > div {
          box-shadow: none;

          span {
            font-size: 12px;
            color: $system-black;
            padding-left: 15px;
          }

          i {
            color: #000;
            font-size: 12px;
            background-color: transparent;
            padding: 0 3px;

            &:hover {
              color: $primary-color;
              background-color: transparent;
            }
          }
        }
      }

      .controls {
        margin-left: 20px;

        div {
          display: flex;
          font-family: $font-family;
          font-size: 12px;
          font-weight: 400;
          color: $primary-color;

          &:hover {
            color: $system-black;
          }
        }
      }
    }
  }

  &.disabled {
    .controls {
      div {
        color: $disabled-color;
      }

      span {
        background-color: $disabled-color;
      }
    }
  }
}

.popup {
  padding: 10px;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 18px;
      margin: 20px 0;
    }
  }
}
