@import 'helpers';

.login {
  display: flex;
  width: 100%;
  height: 100%;

  background: url('../../../assets/images/login_background.png') no-repeat;
  background-size: contain;

  @media screen and (max-width: 1100px) {
    background-size: cover;
  }

  > div {
    display: flex;
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;

    box-shadow: 0px 0px 40px rgba(100, 100, 100, 0.2);
  }

  .formContainer {
    @include flex-center;
    flex-direction: column;
    flex: 1;
    padding: 20px 40px;
    height: 580px;
    opacity: 0;
    animation: fadeIn 0.2s ease-out 0.2s forwards;

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 305px;
      margin: auto;

      & > div {
        width: 100%;
      }

      .loginTag {
        font-family: $font-family;
        font-weight: 600;
        font-size: 1.125rem;
        color: rgba(51, 51, 51, 0.8);
        margin-top: 0.75rem;
      }

      .formInfo {
        padding: 0.5rem 0rem;

        margin-top: 0.25rem;
        border: 1px solid #00ad43;
        border-radius: 10px;
        font-size: 0.75rem;
        font-weight: 400;
        font-family: $font-family;

        @include flex-center;
        text-align: center;

        span {
          padding: 0rem 1.5rem;

          strong {
            opacity: 0.8;
          }
        }
      }

      .logos {
        @include flex-center;
        margin: 0rem 0 0.5rem;

        img {
          height: 3.25rem;
        }

        span {
          width: 1px;
          height: 60%;
          margin: 0 15px;
          background-color: #0185d7;
        }
      }
    }

    .footer {
      font-family: $font-family;
      font-size: 0.75rem;
      font-weight: 400;

      color: $regular-color;
      width: 320px;
      text-align: center;
      background-color: transparent;
    }
  }

  .banner {
    font-family: $font-family;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 40px;

    position: relative;
    flex: 0 0 auto;
    width: 440px;

    background-image: linear-gradient(
      to bottom,
      lighten(desaturate(adjust-hue($primary-color, 3), 5.81), 12.75),
      darken(saturate($primary-color, 4.89), 7.65)
    );

    .header {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      position: relative;
      padding-left: 17px;
      margin: 7.755% 5.51%;
      color: #fff;
      font-family: 'Roboto', sans-serif;

      div {
        opacity: 0;
        font-size: 1.625rem;
        font-weight: 400;
        animation: fadeIn 0.2s ease-out 0.2s forwards;

        &:last-child {
          font-size: 1.625rem;
          font-weight: 700;
          width: 80%;
        }
      }

      @keyframes slide {
        0% {
          height: 0;
        }
        100% {
          height: 93px;
        }
      }
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 103.396%;
    }
  }
}

.version {
  @include flex-center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 1367px) {
  .login {
    > div {
      zoom: 0.7;
    }
  }
}
